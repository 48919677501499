'use strict';

var util = require('../../util');

/**
 * @function Blog related functions
 */
exports.init = function () {  

    $('.article-list-item').slice(0, 9).show();
    if ($('.article-list-item').length > 9) {
        $('#more-posts-link').show();
    }
    $('#more-posts-link').on('click', function (e) {
        e.preventDefault();
        $('.article-list-item:hidden').slice(0, 3).slideDown();
        if ($('.article-list-item:hidden').length == 0) {
            $('#load').fadeOut('slow');
            $('#more-posts-link').hide();
        }
        $('html,body').animate({
            scrollTop: $(this).offset().top
        }, 1500);
    });

    $('.blog-current-folder').on('click', function (e) {
        if (util.isMobileSize()) {
            e.preventDefault();
            $('.blog-navigation').toggleClass('active');
        }
    })
}
