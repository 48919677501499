'use strict';
var dialog = require('../../dialog');
var util = require('../../util');
var qs = require('qs');
var url = require('url');
var _ = require('lodash');
var carousel = require('../../carousel');

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage (atts) {
    $('#pdpMain .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    updatePinButton(atts.url);
    if (!dialog.isActive() && !util.isMobile()) {
        $('#pdpMain .main-image').attr('href', atts.hires);
    }
}

function updatePinButton (imageUrl) {
    var pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    var newUrl = imageUrl;
    if (!imageUrl) {
        var img = document.querySelector('#pdpMain .product-image');
        if (!img) {
            return;
        }
        newUrl = img.getAttribute('src');
    }
    var href = url.parse(pinButton.href);
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    var newHref = url.format(_.extend({}, href, {
        query: query, // query is only used if search is absent
        search: qs.stringify(query)
    }));
    pinButton.href = newHref;
}

function imagesModal () {
 // Product image zoom functionality
    var $modal = $('.product-image-modal');
    var $modalClose = $('.product-image-modal-close');

    // Handle image zoom on Quickview
    var quickViewDialog = $('.pdp-main').closest('#QuickViewDialog');
    if (quickViewDialog.length === 1) {
        $('.product-image-zoom').on('click', function() {
            quickViewDialog.find('.product-image-quickview').html($(this).siblings('img').clone());
        }).first().trigger('click');
    } else {
        // Handle image zoom on PDP
        $('.product-image-container').on('click', '.product-image-zoom', function() {
            var slideno = $(this).prev('img').data('slide');
            $('.zoom-images-carousel').slick('slickGoTo', slideno - 1, true);
            $modal.trigger('open');
        });
    }

    // Modal open/close events
    $modal
        .on('open', function () {
            $('body').addClass('product-image-modal-visible');
            // Press escape to close the modal
            $(document).on('keyup.modal-close', function (e) {
                if (e.which === 27) {
                    $modal.trigger('close');
                }
            });
            $(document).on('keydown', function(e) {
                if (e.keyCode == 37) {
                    $('.zoom-images-carousel').slick('slickPrev');
                }
                if (e.keyCode == 39) {
                    $('.zoom-images-carousel').slick('slickNext');
                }
            });
        })
        .on('close', function () {
            $('body').removeClass('product-image-modal-visible');
            $(document).off('keyup.modal-close');
        });

    // Modal close button handler
    $modalClose.on('click', function () {
        $modal.trigger('close');
    });
}

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();

    // Handle image loading in Quickview
    var quickViewDialog = $('.pdp-main').closest('#QuickViewDialog');
    if (quickViewDialog.length === 1) {
        $('.product-image-zoom').on('click', function() {
            quickViewDialog.find('.product-image-quickview').html($(this).siblings('img').clone());
        }).first().trigger('click');
    } else {
        carousel.loadPdp();
        carousel.loadPdpZoom();
        imagesModal();
    }
}

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    updatePinButton();
    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', function () {
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');

        setMainImage($(this).data('lgimg'));
    });
    imagesModal();
};
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
module.exports.imagesModal = imagesModal;
