/**
* Description of the module and the logic it provides
*
* @module cartridge/js/wishlist_status
*/

'use strict';

var WishListStatus = {
    init: function () {
        var handler = WishListStatus.guestHandler;
        if (!window.User.anonymous) {
            handler = WishListStatus.customerHandler;
        }
        $(document).on('click', '.add-to-favorites', handler);
    },

    guestHandler: function (e) {
        e.preventDefault();
        var elem = $(this),
            pid = elem.data('pid'),
            colorid = elem.data('colorid'),
            recipeId = elem.data('recipe-id') || '';
        window.location.href = window.Urls.wishlistAdd + '?pid=' + pid + '&colorid=' + colorid + '&recipeId=' + recipeId + '&source=productdetail';
    },

    customerHandler: function (e) {
        e.preventDefault();
        var elem = $(this),
            pid = elem.data('pid'),
            colorid = elem.data('colorid'),
            recipeId = elem.data('recipe-id') || '',
            url = window.Urls.wishlistAjax + '?pid=' + pid + '&colorid=' + colorid + '&recipeId=' + recipeId + '&format=ajax';

        $.getJSON(url, function (data) {
            if (data && data.success) {
                $('.add-to-favorites[data-pid="' + pid + '"]').toggleClass('added', data.isAdded);
            }
        })
    },

    getStatus: function () {
        var pid = $('#pid').val();
        if (pid) {
            $.ajax({
                url: window.Urls.wishlistStatus + '?pid=' + pid + '&format=ajax',
                dataType: 'html'
            }).done(function (data) {
                if (data) {
                    $('.wishlist-icon-wrap').replaceWith(data);
                }
            });
        }
    }
}

module.exports = WishListStatus;
