'use strict';

var inventory = require('./'),
    dialog = require('../dialog'),
    util = require('../util'),
    page = require('../page');

var cartInventory = {
    setSelectedStore: function (storeId) {
        var $selectedStore = $('.store-tile.' + storeId),
            $lineItem = $('.cart-row[data-uuid="' + this.uuid + '"]'),
            storeAddress = $selectedStore.find('.store-address').html(),
            storeStatus = $selectedStore.find('.store-status').data('status'),
            storeStatusText = $selectedStore.find('.store-status').text();
        this.selectedStore = storeId;

        $lineItem.parent().find('.instore-delivery .selected-store-address')
            .data('storeId', storeId)
            .attr('data-store-id', storeId)
            .html(storeAddress);
        $lineItem.parent().find('.instore-delivery .selected-store-availability')
            .data('status', storeStatus)
            .attr('data-status', storeStatus)
            .text(storeStatusText);
    },
    cartSelectStore: function (selectedStore) {
        var self = this;
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({'address': User.zip}, function (results, status) { 
            if (status === window.google.maps.GeocoderStatus.OK && results[0].geometry.location) {
                var location = results[0].geometry.location;
                var params = {
                    pid: self.pid,
                    lat: location.lat(),
                    long: location.lng()
                };
                $.ajax({
                    url: util.appendParamsToUrl(Urls.storesInventory, params),
                    dataType: 'json',
                    success: function (stores) {
                        inventory.selectStoreDialog({
                            stores: stores,
                            selectedStoreId: selectedStore,
                            selectedStoreText: Resources.PREFERRED_STORE,
                            pid: params.pid,
                            lat: params.lat,
                            long: params.long,
                            continueCallback: function () {},
                            selectStoreCallback: self.setSelectedStore.bind(self),
                            changeLocationCallBack: function () {
                                inventory.setUserZip(null);
                                $('.item-delivery-options .set-preferred-store').trigger('click');
                            }
                        });
                    }
                })
            }
        });
    },
    setDeliveryOption: function (value, storeId, plid, status) {
        // set loading state
        $('.item-delivery-options')
            .addClass('loading')
            .children().hide();

        var data = {
            plid: plid.length > 0 ? plid : this.uuid,
            storepickup: (value === 'store' ? true : false)
        };
        if (value === 'store') {
            data.storepickup = true;
            data.storeid = storeId;
            data.status = status;
        } else {
            data.storepickup = false;
        }
        $.ajax({
            url: Urls.setStorePickup,
            data: data,
            success: function () {
                // remove loading state
                $('.item-delivery-options')
                    .removeClass('loading')
                    .children().show();
            }
        });
        if ('ApplePaySession' in window && User.storeId) {
            // we need to refresh in order to update the Apple Pay session based on delivery option selection
            page.refresh();
        }
    },
    togglePayPalButton: function () {
        $('.paypal-button').show();
    },
    hideShowStoreDetails: function (deliveryOption, plid) {
        var $lineItem = $('.cart-row[data-uuid="' +plid+ '"]'),
            $storeDetails = $lineItem.find('.selected-store-name, .selected-store-address, .selected-store-availability, .isavail'),
            $preferredStoreLink = $lineItem.find('.set-preferred-store');

        if (deliveryOption == 'home') {
            // hide store details when home delivery option is selected
            $storeDetails.addClass('hidden');
            $preferredStoreLink.addClass('hidden');
        } else { // show store details when in-store option is selected
            $storeDetails.removeClass('hidden');
            $preferredStoreLink.removeClass('hidden');
        }
    },
    updateCartRowLinks: function (cartRow) {
        var $editLink = $(cartRow).find('.item-edit-details a'),
            $favoritesLink = $(cartRow).find('a.add-to-wishlist'),
            isISPU = $(cartRow).find('.instore-delivery input').is(':checked'),
            edithref,
            favoriteshref;

        // update links if they exist
        if ($editLink.length && $editLink.attr('href') && $favoritesLink.length && $favoritesLink.attr('href')) {
            if (isISPU) {
                edithref = $editLink.attr('href') + '&ispu=true';
                favoriteshref = $favoritesLink.attr('href') + '&ispu=true';
            } else {
                edithref = $editLink.attr('href').replace('&ispu=true','');
                favoriteshref = $favoritesLink.attr('href').replace('&ispu=true','');
            }
            $editLink.attr('href', edithref);
            $favoritesLink.attr('href', favoriteshref);
        }
    },
    init: function () {
        var self = this;
        var newLine = '\n';
        var loadingTemplate = function (stores, selectedStoreId, selectedStoreText, pid) {
            return [
                '<div id="preferred-store-panel">',
                '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
                '     <div class="zip-retry-form-row">',
                '     <div class="zip-retry-inpu-wrapper">',
                '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
                '           <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
                '     </div>',
                '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
                '     </div>',
                '</div>',
                '<div class="store-list-container loading-template">',
                '    <div class="ispu-loader">',
                '    </div>',
                '</div>',
                '<div class="store-list-pagination">',
                '</div>'
            ].join(newLine);
        };
        
        var loadingOptions = {
            modal: true,
            width: 'auto',
            maxWidth: '900',
            height: '670',
            dialogClass: 'ispu-select-store'
        };
        
        $(document).on('change', '.delivery-option', function (e) {
            var value = $(this).parent().find(':checked').val(),
                storeId = $('.selected-store-address').data('store-id'),
                status = $(this).closest('.instore-delivery').find('.selected-store-availability').data('status'),
                plid = $(e.target).data('uuid'),
                inventoryAmt = $(this).closest('tr').find('.inventoryAmt').val();

            self.setDeliveryOption(value, storeId, plid, status);
            cartInventory.hideShowStoreDetails(value, plid);
            cartInventory.updateCartRowLinks($(this).closest('.cart-row'));

            // if store option is selected but no inventory is available, auto-select home delivery
            if (value == 'store' && Number(inventoryAmt) == 0) {
                var $cartRow = $(this).closest('.cart-row');
                $cartRow.find('input[value="home"]').trigger('click');
                $cartRow.find('.selected-store-name, .selected-store-address, .selected-store-availability, .isavail').addClass('hidden');
                $cartRow.find('.set-preferred-store').removeClass('hidden');
            }
        });
        $(document).on('click', '.item-delivery-options .set-preferred-store', function (e) {
            e.preventDefault();
            self.uuid = $(this).data('uuid');
            self.pid = $(this).data('pid');
            var selectedStore = $(this).closest('.item-delivery-options').find('.selected-store-address').data('store-id');
            var personalized = $(this).closest('.cart-row').hasClass('personalized');

          //initial dialog to let the user know stores are loading
            dialog.open({
                html: loadingTemplate,
                options: loadingOptions
            });
            if (User.storeId && User.zip) {
                self.cartSelectStore(selectedStore);
            } else {
                var url = Urls.getGeolocation,
                    geolocationObj = null;

                $.getJSON(url, function (data) {
                    if (data) {
                        geolocationObj = data;
                    } else {
                        navigator.geolocation.getCurrentPosition(function (pos) {
                            const coords = pos.coords;
                            if (coords.latitude && coords.longitude) {
                                geolocationObj.available = true;
                                geolocationObj.location = {
                                    lat: coords.latitude,
                                    long: coords.longitude
                                }
                            }
                        });
                    }
                }).done(function() {
                    if (geolocationObj && geolocationObj.available) {
                        const location = geolocationObj.location;
                        self.pid = $(e.target).data('pid');
                        inventory.showStoresByGeolocation(location.lat, location.long, self.pid, personalized);
                    } else if (!User.zip) {
                        inventory.zipPrompt(function () {
                            self.cartSelectStore(selectedStore);
                        });
                    } else {
                        self.cartSelectStore(selectedStore);
                    }
                });
            }
            $(document).on('click', '.item-delivery-options .delivery-option', function () {
                var item = $(this);
                var uuid = item.data('uuid');
                var parent = $('.cart-row[data-uuid="' + uuid + '"]'),
                    selectedStore = parent.find('.selected-store-address').data('storeId'),
                    status = $(this).closest('.instore-delivery').find('.selected-store-availability').data('status');
                self.uuid = uuid;
                self.pid = parent.data('pid');
                self.setDeliveryOption(item.val(), selectedStore, uuid, status);
            });
        });
        
        $('.delivery-option').on('click', function() {
            // trigger store selection if ISPU is chosen and no store is saved
            var isISPU = $(this).val().toLowerCase() == 'store',
                isStoreSelected = User.storeId,
                $preferredStoreLink = $(this).closest('tr').find('.set-preferred-store'),
                pid = $(this).closest('tr').find('input.cart-pid').val();

            if (isISPU && !isStoreSelected) {
                $.ajax({
                    url: util.appendParamsToUrl(Urls.markForIspu, {pid : pid}),
                    success: function () {
                        $preferredStoreLink.trigger('click');
                    }
                });
            }
        });
        $('.cart-row').on('click', '.item-delivery-options', function(e) {
            if (!$(e.target).hasClass('home-delivery') && !$(e.target).parent().hasClass('home-delivery')) {
                var $inventoryErrors = $(this).find('.inventory-error, .notAvailForIspuMessage');
                if ($inventoryErrors.length) {
                    $inventoryErrors.removeClass('visually-hidden');
                }
            }
        });
        // update Edit Details, Save to Favorites links with ISPU parameter, if ISPU is selected on the lineitem
        $('.cart-row ').each(function() {
            cartInventory.updateCartRowLinks(this);
        });

        $('.item-delivery-options').click(function() {
            self.togglePayPalButton();
        });
        self.togglePayPalButton();
        
        // update lineitem delivery options if there is no stock for ISPU
        $('.inventory-error').each(function() {
            var $cartRow = $(this).closest('tr');
            $cartRow.find('input[value="home"]').trigger('click');
            $cartRow.find('.instore-delivery').addClass('unavailable');
            $cartRow.find('.set-preferred-store').removeClass('hidden');
        });
        
        // update selection and hide store info for completely OOS lineitems
        $('.full-out-of-stock').each(function() {
            $(this).find('input[value="home"]').removeAttr('disabled').trigger('click');
            $(this).find('.instore-delivery').addClass('unavailable');
            $(this).find('.store-info-wrap').addClass('hidden');
        });
    }
};

module.exports = cartInventory;
