'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    accordion = require('./accordion');

/**
 * @description Move split swatch pricing to be above swatch
 **/
var splitSwatchPricing = function () {
    var splitSwatchPrice = $('.split-swatch-pricing');
    if ($(splitSwatchPrice).length) {
        $(splitSwatchPrice).show();
        $(splitSwatchPrice).each(function () {
            var prev = $(this).prev();
            $(this).insertBefore(prev);
        })
    }
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {

    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };

    progress.show($('#pdpMain'));

    $(document).trigger('load-variant:before');

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            //accordion.init('.product-accordion');
            var rowIdexToOpen = 0
            if ($('#addtocartlocation').val() && $('#addtocartlocation').val().length > 0 && $('#addtocartlocation').val() == 'quickview') {
                rowIdexToOpen = 10;
            }
            accordion.init('.product-accordion', null, null, rowIdexToOpen);
            $(document).trigger('load-variant:after');
            if (window.$BV && window.configData) {
                window.$BV.ui('rr', 'show_reviews', window.configData);
            }
            $('.product-usd').removeClass('hide-USD');
            var $sizeChartLink = $('.size-chart-link');
            if ($sizeChartLink.length > 0) {
                $('#personalize').addClass('withSizeChart');
            }
            var $inStoreOption = $('.pt_product-details .instore-delivery');
            if ($inStoreOption.length && $inStoreOption.is(':visible')) {
                //add withoutAddress class to ispu error message and hide change store in cases where size is unselected
                var addressText = $('.selected-store-address').text();
                if (addressText == '') {
                    $('.in-store-pickup-error').addClass('withoutAddress');
                    $('.change-store').addClass('hidden');
                }
                var homeDeliverySelected = $('#delivery_option_home').attr('checked');
                var notEligible = $('.notAvailForIspuMessage').length,
                    isQuickView = $('#QuickViewDialog').length,
                    isAddToBagDisabled = $('#add-to-cart').is(':disabled'),
                    isISPU = href.indexOf('ispu') > -1;
                if ($('.in-store-pickup-error.hidden').length < 1 && !homeDeliverySelected
                    && isISPU && !notEligible && !isQuickView && !isAddToBagDisabled) {
                    $('.instore-delivery .delivery-option').trigger('click');
                }
                if (notEligible.length > 0) {
                    $('.instore-delivery').addClass('notEligible');
                }
            }
            if ($('.unselectable.selected').length > 0) {
                $('.availability-message').addClass('hidden');
            }
        }
    });
};

module.exports = function () {
    var $pdpMain = $('#pdpMain');
    // hover on swatch - should update main image with swatch image
    $pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
        var largeImg = $(this).data('lgimg'),
            $imgZoom = $pdpMain.find('.main-image'),
            $mainImage = $pdpMain.find('.primary-image');

        if (!largeImg) { return; }
        // store the old data from main image for mouseleave handler
        $(this).data('lgimg', {
            hires: $imgZoom.attr('href'),
            url: $mainImage.attr('src'),
            alt: $mainImage.attr('alt'),
            title: $mainImage.attr('title')
        });
        // set the main image
        image.setMainImage(largeImg);
    });
    // add class to truefit and monogram link if size chart is present
    var $sizeChartLink = $('.size-chart-link');
    if ($sizeChartLink.length > 0) {
        $('.tfc-fitrec-product, #personalize').addClass('withSizeChart');
    }
    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    splitSwatchPricing();
};
