'use strict';

var minicart = require('./minicart'),
    util = require('./util');

var setAddToCartHandler = function (e) {
    e.preventDefault();
    var form = $(this);
    if (!($('#g-recaptcha-response').length && $('#g-recaptcha-response').val() =='')) {
        //if captcha is added but empty we should not trigger request
        var options = {
            url: util.ajaxUrl(form.attr('action')),
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function (response) {
            minicart.show(response);
            form.find('input,textarea').not(':hidden').not('[readonly]').val('');
            try {
                window.grecaptcha.reset();
                $('#g-recaptcha-response').val('');
            } catch (e) {
                return true;
            }
        }).fail(function (xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    } else return true;
};

var stripBadChars = {
    init: function () {
        $('.validate-bad-chars').on('change', function () {
            $(this).val(stripBadChars.strip($(this).val()));
        });
    },
    strip: function (str) {
        var invalidCharacters = RegExp(window.SitePreferences.GIFT_MESSAGE_INVALID_CHARACTERS, 'gi');
        return str.replace(invalidCharacters, '');
    }
}

exports.init = function () {
    //Choose Classic or E Gift Card Form and Highlight Card Choice
    $('.card-type').on('click', function() {
        var item = $(this);
        $('.hidden-elements').toggle((item.attr('id') == 'electronic-card'));
        $('.card-type').not(this).removeClass('active').addClass('inactive');
        $(this).addClass('active').removeClass('inactive');
        $('input[name="pid"]').val(item.data('productid'));
        $('#GiftCertificateForm, hr').fadeIn();
    });

    $('.amounts .amount').on('click', function () {
        var item = $(this);
        $('.amounts').find('.selected').removeClass('selected');
        item.addClass('selected');
        $('input[name="amount"]').val(item.data('value'));
    })
    $('input[name$="_sendDate"]').datepicker({
        minDate: 0
    });

    $('#AddToBasketButton').attr('disabled', 'disabled').prop('disabled', true);
    $('#GiftCertificateForm').find('.required').on('change', function () {
        var form = $('#GiftCertificateForm'),
            addToBag = $('#AddToBasketButton');
        if (!form.valid()) {
            addToBag.attr('disabled', 'disabled').prop('disabled', true);
        } else {
            addToBag.attr('disabled', '').prop('disabled', false);
        }
    })
    // if customer is registered, make from email readonly
    if ($('input[name$="_fromEmail"]').val()) {
        $('input[name$="_fromEmail"]').prop('readonly', true);
    }
    stripBadChars.init();
    $('#GiftCertificateForm').on('submit', setAddToCartHandler);
};
