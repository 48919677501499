'use strict';

var util = require('../util');

var subscribe = {
    init: function () {
        var $form = $('#email-alert-signup');

        $('#email-alert-address')
            .attr('type', 'email')
            .on('focusout', function() {
                if (!$form.valid()) {
                    $form.find('.form-response').addClass('visually-hidden').html('');
                    $form.find('.form-row').addClass('invalid-email');
                }

                if ($form.valid() && $form.find('.form-response').hasClass('visually-hidden')) {
                    $form.find('.form-row').removeClass('invalid-email');
                }
            });

        $('#email-alert-signup').on('submit', function (e) {
            e.preventDefault();
            subscribe.submitForm($(this));
        })
    },
    submitForm: function(form) {
        var $form = form,
            formResponse = $form.find('.form-response');

        if (!$form.valid()) {
            return false;
        }

        // Disable the button so no more clicks can occur until the request is complete
        $form.find('button[type="submit"]').prop('disabled', true);

        // submit the form
        var email = $form.find('#email-alert-address').val();
        var gender = '';
        var genderRadio = $form.find('input[name$="maillistgender"]:checked');
        if (genderRadio.length > 0) {
            gender = genderRadio.val();
        }

        if (form.valid()) {
            var msg = {
                email: email,
                gender: gender
            };
            var url = util.appendParamToURL(Urls.footerSubscribe, 'format', 'ajax');
            $.post(url, msg, function (data) {
                if (data) {
                    if (data.success == true) {
                        formResponse.html(Resources.SUBSCRIBE_SUCCESS).removeClass('visually-hidden');
                        $form.find('#email-alert-address').val('');
                    } else {
                        formResponse.html(data.errorMessage).removeClass('visually-hidden');
                        $form.find('.form-row').addClass('invalid-email');
                    }
                } else {
                    formResponse.html(Resources.SUBSCRIBE_SYSTEMERROR).removeClass('visually-hidden');
                }
                $form.find('button[type="submit"]').prop('disabled', false);
            },'json');
            $form.find('.form-row').removeClass('invalid-email');
        }
    }
}

module.exports = subscribe;
