'use strict';

var sizechart = function () {
    $('.size-chart-header').unbind('click').on('click', function() {
        $(this).children('span').toggleClass('rot180-svg');
        $(this).next('.size-chart-wrapper').slideToggle('fast');
    });
}

var unitcontrol = function () {
    $('.alternate-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        } else {
            $(this).prev('.main-toggle').removeClass('active');
            $(this).toggleClass('active');
            $(this).parent().next('table').find('.inches').hide();
            $(this).parent().next('table').find('.cm').show();
        }
    })
    $('.main-toggle').on('click', function () {
        if ($(this).hasClass('active')) {
            return;
        } else {
            $(this).next('.alternate-toggle').removeClass('active');
            $(this).toggleClass('active');
            $(this).parent().next('table').find('.cm').hide();
            $(this).parent().next('table').find('.inches').show();
        }
    })
}

module.exports = function () {
    sizechart();
    unitcontrol();
}
