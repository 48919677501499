'use strict';

var product = require('./pages/product'),
    carousel = require('./carousel'),
    util = require('./util');

var multisell = {
    init : function () {
        var $thumbs = $('.js-multisell-thumbnail');
        if ($thumbs.length) {
            $thumbs.on(
                'click',
                function (e) {
                    e.preventDefault();
                    var $link = $(this).find('.js-multisell-thumbnail-link'); 
                    var url = util.appendParamToURL($link.attr('href'),
                        'source', 'multisell');
                
                    $(this).addClass('multisell__thumbnail--active');
                    $thumbs.not(this).removeClass('multisell__thumbnail--active');

                    $.ajax({
                        url : url,
                        success : function success(response) {
                            $('#content').empty().html(response);
                            product.initializeEvents();
                            $('.product-usd').removeClass('hide-USD');
                            carousel.loadPdp();
                            carousel.loadPdpZoom();
                        },
                        error: function() {
                            $('#content').empty();
                        }
                    });
                });
        }
    }
}

module.exports = multisell;
