'use strict';

var ajax = require('./ajax');
/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
exports.checkBalance = function (num, pin, callback) {
    // load gift certificate details
    var url = Urls.giftCardCheckBalance + '?giftCertCode=' + num + '&giftCertPin=' + pin;

    ajax.getJson({
        url: url,
        callback: callback
    });
};
