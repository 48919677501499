'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    fluidconfigure = require('../../fluidconfigure'),
    util = require('../../util'),
    suggestedAddress = require('../../avs/suggested');

var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');

    if ($('.gift-message-text').hasClass('hidden')) {
        $('.gift-message-text').attr('aria-hidden', 'true');
    } else {
        $('.gift-message-text').removeAttr('aria-hidden');
    }
    $('textarea[name$="_shippingAddress_giftMessage"]').change(function() {
        $(this).val(stripInvalidCharacters($(this).val()));
    })
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    var fakeCBtn = $summary.find('#fakeContinueBtn');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', '2');
    $summary.load(summaryUrl, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        $summary.find('.summary-bottom .form-row-button').empty().prepend(fakeCBtn.text(Resources.CONTINUE));

        fluidconfigure.initMiniLineItems();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        disableContinueButton();
        return;
    }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }

            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.

                // we need to update shipping methods even if list is not changed to update delivery estimation date
                //return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
                disableContinueButton();
            });

        }
    });
    disableContinueButton();
}

/**
 * used in multiple places so moved to separate function
 */
function disableContinueButton () {
    /**
     * disable submit button in case if we have shipping error message (hazmat items) or at least one required field is empty
     */
    if ($('.no-shipping-methods').size() > 0
            || $('input[name$="_addressFields_address1"]').val() === ''
            || $('input[name$="_addressFields_city"]').val() === ''
            || $('input[name$="_addressFields_postal"]').val() === '') {
        $('button[name$="shippingAddress_save"]').attr('disabled', 'disabled');
    } else {
        $('button[name$="shippingAddress_save"]').removeAttr('disabled');
    }
}

function toggleEmailGenderOptions () {
    if ($('.account-newsletter input').is(':checked')) {
        $('.optin-input').show();
    } else {
        $('.optin-input').hide();
    }
}

function stripInvalidCharacters (str) {
    var invalidChars = RegExp(window.SitePreferences.GIFT_MESSAGE_INVALID_CHARACTERS, 'gi');
    return str.replace(invalidChars, '');
}

function updateShippingDetailsJson(address) {
    var url = util.appendParamToURL(Urls.updateAddressDetailsJson, 'address', JSON.stringify(address));
    ajax.load({
        url: url
    });
}

exports.init = function () {
    window.checkoutShippingPage = true;
    suggestedAddress.init();
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );
    giftMessageBox();
    updateShippingMethodList();
    $('select[name$="_state"]').on('change', function () {
        if ($(this).val()==='') {
            $(this).parent().addClass('select-style-error');
        } else {
            $(this).parent().removeClass('select-style-error');
        }
    })

    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]',
        fakeContinue:'#fakeContinueBtn'
    });

    $('#secondary').on('click','#fakeContinueBtn', function(e) {
        e.preventDefault();
        $('button[name$="_shippingAddress_save"]').trigger('click');
    });
    
    
    $('.account-newsletter input').change(function () {
        toggleEmailGenderOptions();
    })

    disableContinueButton();
    toggleEmailGenderOptions();
    formPrepare.validateForm();
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.updateShippingDetailsJson = updateShippingDetailsJson;
