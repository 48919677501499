'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    variant = require('./variant'),
    wlStatus = require('../../wishlist_status'),
    giftcard = require('../../giftcard'),
    giftcert = require('../../giftcert'),
    sizechart = require('../../sizechart'),
    accordion = require('./accordion'),
    fluidconfigure = require('../../fluidconfigure'),
    progress = require('../../progress'),
    event = require('../../omniture/event');
/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    tooltip.init();
    wlStatus.init();
    giftcert.init();
    fluidconfigure.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart();
    availability();
    variant();
    image();
    sizechart();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    // Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable:not(.extended-sizing-tile) a', function (e) {
        e.preventDefault();
    });
    //Firing size chart js after dialog is open
    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            callback: function () {
                sizechart();
            }
        });
    });

    // Initalize product accordion
    // for PDP rowIdexToOpen = 0
    // for quickview modal = 10 to prevent open first item on window open
    var rowIdexToOpen = 0
    if ($('#addtocartlocation').val() && $('#addtocartlocation').val().length > 0 && $('#addtocartlocation').val() == 'quickview') {
        rowIdexToOpen = 10;
    }
    accordion.init('.product-accordion', null, null, rowIdexToOpen);

    if ($('.gift-certificate-purchase').size() > 0) {
        $('#CheckBalanceButton').on('click', function (e) {
            e.preventDefault();
            progress.show($('.check-balance-input-container'));
            var $giftCertCode = $('input[name$="_giftCertID"]');
            var $giftCertPin = $('input[name$="_giftCertPIN"]');
            var $balance = $('.balance'),
                error = $('.check-balance-form').find('.form-row.error');

            if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
                error.html(Resources.GIFT_CERT_MISSING);
                progress.hide();
                return;
            }

            if ($giftCertPin.length === 0 || $giftCertPin.val().length === 0) {
                error.html(Resources.GIFT_CERT_PIN_MISSING);
                progress.hide();
                return;
            }

            giftcard.checkBalance($giftCertCode.val(), $giftCertPin.val(), function (data) {
                if (!data || !data.giftCertificate || !data.message) {
                    error.html(Resources.GIFT_CERT_INVALID);
                    progress.hide();
                    return;
                }
                progress.hide();
                $balance.html(data.message).addClass('success');
            });
        });
    }

    // Disable the swatch hover event that changes the primary (image) on the page
    $pdpMain.off('mouseenter mouseleave', '.swatchanchor');

    $(document).on('click', '.fit-options .items .name', function () {
        var item = $(this),
            obj = item.data('obj');
        $('.fit-options').find('.selected').removeClass('selected');
        item.addClass('selected');

        // event167
        // Change Fit Model
        // Counter event to trigger whenever a fit model selector is available on the page and the visitor selects a fit model.
        event.pushEvent('event167', null, true);

        // event166
        // Fit Model Indicator
        // Counter event to trigger whenever a fit model selector is available on the page.
        event.pushEvent('event166', null, true);
        event.push();

        $('.fit-options').find('.description').html(obj.size);
        $('.product-images .product-image, .product-image-quickview').each(function () {
            var img = $(this).is('img') ? $(this) : $(this).find('img'),
                src = img.data('originsrc'),
                hires = img.data('originhires');
            img.attr('src', src.replace(/\?\$/,'_' + obj.shotType + '?$'));
            img.attr('data-hires', hires.replace(/\?\$/,'_' + obj.shotType + '?$'));
        })
        $('.product-image-modal .product-image').each(function () {
            var img = $(this).is('img') ? $(this) : $(this).find('img'),
                imageOptions = '?' + img.data('zoom'),
                src = img.attr('src').split('?')[0] + imageOptions;
            console.log(img);//eslint-disable-line
            img.attr('src', src);
        })
    });
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
    }
};

module.exports = product;
