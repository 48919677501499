'use strict';

var util = require('./util')

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
    $(document).tooltip({
        items: '.tooltip',
        track: true,
        content: function () {
            return $(this).find('.tooltip-content').html();
        }
    });

    if (util.isMobileSize()) {
        $('.tooltip').on('click', function(e) {
            if ($('.ui-tooltip').length) {
                $('.tooltip[tooltip-open=true]').tooltip('disable').attr('tooltip-open', false);
            }
            e.preventDefault();
            event.stopPropagation();
            $(this).tooltip({
                items: '.tooltip',
                content: $(this).find('.tooltip-content').html()
            });
            $(this).tooltip('enable');
            $(this).tooltip('open');
            $(this).attr('tooltip-open', true);
        });
        $(document).on('click', function(event) {
            if (!$(event.target).is('.tooltip') && $('.ui-tooltip').length) {
                $('.tooltip[tooltip-open=true]').tooltip('disable').attr('tooltip-open', false);
            }
        });
    }

    $('.share-link').on('click', function (e) {
        e.preventDefault();
        var target = $(this).data('target');
        if (!target) {
            return;
        }
        $(target).toggleClass('active');
    });

    //open close tooltip with spacebar on focus for ADA
    $('body').on('focus', '.tooltip', function() {
        let toolTipFocusToggle = true;

        //reinit tooltip so can call open and close on it
        $(this).tooltip({
            items: '.tooltip',
            content: $(this).find('.tooltip-content').html()
        });
        $(this).tooltip('enable');
        $(this).tooltip('open');

        $(this).keyup(function(e) {
            if (e.which == 32 || e.which === 13) {
                e.stopPropagation();
                if (toolTipFocusToggle) {
                    $(this).tooltip('close');
                    toolTipFocusToggle = false;
                } else {
                    $(this).tooltip('open');
                    toolTipFocusToggle = true;
                }

            }
            //prevent scroll on space bar
            $(this).keydown(function(e) {
                if (e.which === 32) {
                    e.preventDefault();
                }
            });
        });
    });
};
