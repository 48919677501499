'use strict';

var _ = require('lodash'),
    util = require('../util'),
    dialog = require('../dialog'),
    inventory = require('./');

var instoreDeliveryComponents = $('.instore-delivery label[for="delivery_option_store"], .instore-delivery, .selected-store-address');
var newLine = '\n';
var pdpStoreTemplate = function (store) {
    return [
        '<li class="store-list-item ' + (store.storeId === User.storeId ? ' selected' : '') + '">',
        '    <div class="store-address">' + store.address1 + ', ' + store.city + ' ' + store.stateCode +
        ' ' + store.postalCode + '</div>',
        '    <div class="store-status" data-status="' + store.statusclass + '">' + store.status + '</div>',
        '</li>'
    ].join(newLine);
};
var pdpStoresListingTemplate = function (stores) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-pdp-container">',
            (stores.length > 1 ? '    <a class="stores-toggle collapsed" href="#">' + Resources.SEE_MORE + '</a>' : ''),
            '    <ul class="store-list-pdp">',
            _.map(stores, pdpStoreTemplate).join(newLine),
            '    </ul>',
            '</div>'
        ].join(newLine);
    }
};

var storesListing = function (stores) {
    // list all stores on PDP page
    if ($('.store-list-pdp-container').length) {
        $('.store-list-pdp-container').remove();
    }
    $('.availability-results').append(pdpStoresListingTemplate(stores));
};

var productInventory = {
    productSelectStore: function () {
        var self = this;
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({'address': User.zip}, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK && results[0].geometry.location) {
                var location = results[0].geometry.location;
                var params = {
                    pid: self.pid,
                    lat: location.lat(),
                    long: location.lng()
                };
                $.ajax({
                    url: util.appendParamsToUrl(Urls.storesInventory, params),
                    dataType: 'json',
                    success: function (stores) {
                        inventory.selectStoreDialog({
                            stores: stores,
                            pid: params.pid,
                            selectedStoreId: User.storeId,
                            selectedStoreText: Resources.PREFERRED_STORE,
                            lat: params.lat,
                            long: params.long,
                            continueCallback: function () {
                                storesListing(stores)
                            },
                            selectStoreCallback: inventory.setPreferredStore
                        });
                    }
                })
            }
        });
    },
    changeLocationCallBack: function () {
        inventory.setUserZip(null);
        // trigger the event to start the process all over again
        $('.set-preferred-store').trigger('click');
    },
    updateAvailabilityMessaging: function(isMonogrammed) {
        var $statusMsg = $('.store-info-wrap').find('.isavail'),
            $nonMonogramStatus = $('input#nonMonogramAvailability'),
            $availabilityAttr = $('input#availableInStore');
        if (isMonogrammed) {
            $nonMonogramStatus.val($statusMsg.data('status'));
            $statusMsg.data('status', 'STS_monogramming');
            $statusMsg.text(Resources.ISPU_STS_monogramming);
            $availabilityAttr.val('false');
        } else {
            if ($nonMonogramStatus.val()) {
                var status = $nonMonogramStatus.val();
                $statusMsg.html('<p class="store-status">' + Resources['ISPU_' + status] + '</p>');
                if (status.indexOf('STS') > -1) {
                    $availabilityAttr.val('false');
                } else {
                    $availabilityAttr.val('true');
                }
            }
        }
    },
    hideShowStoreDetails: function (isHomeDelivery) {
        var $storeDetails = $('.store-info'),
            $storeDetailsWrap = $('.store-info-wrap'),
            $preferredStoreLink = $('.set-preferred-store'),
            $storePickUpError = $('.in-store-pickup-error'),
            isMaster = $('#isMasterProduct').val(),
            isQuickView = $('#QuickViewDialog').length,
            itemNotAvailForISPU = $('.notAvailForIspuMessage').length > 0,
            storeNotSelected = $('.selected-store-address').text() == '';

        // if product is a master not on quickview and not pick up unavailable, display the instore link as grayed out
        if (isMaster == 'true') {
            if (!isQuickView) {
                $('.instore-delivery').addClass('unavailable');
                $('.selected-store-address').addClass('unavailable');
            }
            $storePickUpError.removeClass('visually-hidden');
        } else {
            $storePickUpError.not('.visually-hidden').addClass('visually-hidden');
        }
        //do not grey out the ispu radio if user has not selected a store
        if (storeNotSelected) {
            $('.instore-delivery').removeClass('unavailable');
        }
        if (itemNotAvailForISPU && !isQuickView) {
            $('.instore-delivery').addClass('unavailable');
        }

        if (isHomeDelivery) {
            // hide store details, ISPU error message, and change link when home delivery option is selected
            $storeDetails.addClass('hidden');
            $storeDetailsWrap.addClass('hidden');
            $preferredStoreLink.addClass('hidden');
            $storePickUpError.addClass('hidden');
            $('.swatchanchor').each(function (){
                var href = $(this).attr('href').replace('&ispu=true','');
                $(this).attr('href', href);
            });
        } else { // in-store pickup
            $storeDetails.removeClass('hidden');
            $storeDetailsWrap.removeClass('hidden');
            $preferredStoreLink.removeClass('hidden');
            $storePickUpError.removeClass('hidden');
            $('.swatchanchor').each(function (){
                // update swatch URLs with ISPU param to retain in-store option selection when color/size is changed on the PDP
                var href = $(this).attr('href') + '&ispu=true';
                $(this).attr('href', href);
            });
            if (isMaster == 'false') { // hide error and enable "change store" link if a variant is selected
                $preferredStoreLink.find('a').removeClass('disabled');
                $preferredStoreLink.removeClass('disabled');
            } else { // disable links and display error if no variant is selected
                $preferredStoreLink.find('a').addClass('disabled');
                $preferredStoreLink.addClass('disabled');
            }
        }
    },
    init: function () {
        var $availabilityContainer = $('.availability-results'),
            self = this,
            isQuickView = $('#QuickViewDialog').length;
        this.pid = $('input[name="pid"]').val();
        
        var loadingTemplate = function (stores, selectedStoreId, selectedStoreText, pid) {
            return [
                '<div id="preferred-store-panel">',
                '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
                '     <div class="zip-retry-form-row">',
                '     <div class="zip-retry-inpu-wrapper">',
                '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
                '           <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
                '     </div>',
                '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
                '     </div>',
                '</div>',
                '<div class="store-list-container loading-template">',
                '    <div class="ispu-loader">',
                '       <span>&nbsp;</span>',
                '       <span>&nbsp;</span>',
                '       <span>&nbsp;</span>',
                '    </div>',
                '</div>',
                '<div class="store-list-pagination">',
                '</div>'
            ].join(newLine);
        };
        
        var loadingOptions = {
            modal: true,
            width: 'auto',
            maxWidth: '900',
            height: '670',
            dialogClass: 'ispu-select-store'
        };

        $('#product-content .set-preferred-store').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return;
            }
            $(instoreDeliveryComponents).removeClass('unavailable');
            var isMaster = $('#isMasterProduct');
            var variationSelected = $('.size .selectable.selected').length > 0 && $('.color .selectable.selected').length > 0;
            if (isMaster.length > 0 && isMaster.val() == 'true' && !variationSelected) {
                $(this).find('a').addClass('disabled');
                $(this).addClass('disabled');
                return;
            }
            //initial dialog to let the user know stores are loading
            dialog.open({
                html: loadingTemplate,
                options: loadingOptions
            });
            if (User.storeId && User.zip) {
                self.productSelectStore();
            } else {
                var url = Urls.getGeolocation,
                    geolocationObj = null;
                $.getJSON(url, function (data) {
                    if (data) {
                        geolocationObj = data;
                    } else {
                        navigator.geolocation.getCurrentPosition(function (pos) {
                            const coords = pos.coords;
                            if (coords.latitude && coords.longitude) {
                                geolocationObj.available = true;
                                geolocationObj.location = {
                                    lat: coords.latitude,
                                    long: coords.longitude
                                }
                            }
                        });
                    }
                }).done(function() {
                    if (geolocationObj && geolocationObj.available) {
                        const location = geolocationObj.location;
                        const pid = $('input#pid').val();
                        inventory.showStoresByGeolocation(location.lat, location.long, pid);
                    } else {
                        self.productSelectStore();
                    }
                    //scroll to top if mobile to prevent scrolling issue
                    if (util.isMobile()) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        //override dialog positioning
                        var winPosition = {top: '18%', left: '0%'};
                        $(window).resize(function() {
                            $('.ui-dialog').css(winPosition);
                        });
                    }
                });
            }
        });

        if ($availabilityContainer.length) {
            if (User.storeId) {
                inventory.getStoresInventory(this.pid).then(storesListing);
            }

            // See more or less stores in the listing
            $availabilityContainer.on('click', '.stores-toggle', function (e) {
                e.preventDefault();
                $('.store-list-pdp .store-list-item').toggleClass('visible');
                if ($(this).hasClass('collapsed')) {
                    $(this).text(Resources.SEE_LESS);
                } else {
                    $(this).text(Resources.SEE_MORE);
                }
                $(this).toggleClass('collapsed');
            });
        }
        $('#BVRRRatingSummaryLinkReadID a').on('click', function(e) {
            e.preventDefault();
            $('html,body').animate({scrollTop: ($('#BVReviewsContainer').offset().top)-77}, 500);
        });
        
        $('#checkStoreInventory').on('click', function(e) {
            e.preventDefault();
            var pid = $('input[name="pid"]').val();
            var storeId = User.storeId;
            inventory.getStoreInventory(pid,storeId).done(function() {
                if ($('.isavail').length < 1) {
                    $('.ispu-service-error').text(Resources.TRY_LATER);
                }
            });
        });
        
        $('.delivery-option').on('click', function() {
            var isHomeDelivery = $(this).val().toLowerCase() == 'home',
                selectedStore = User.storeId,
                $deliveryInput = $('input#homeDelivery'),
                $fromStoreInput = $('input#fromStoreId'),
                $preferredStoreLink = $('.set-preferred-store'),
                $capacityErrorMsg = $('.ispuCapacityMessage:not(".hidden")');

            // set hidden input values
            $deliveryInput.val(isHomeDelivery);
            if (selectedStore != null) {
                $fromStoreInput.val(selectedStore);
                $capacityErrorMsg.addClass('hidden');
            }
            productInventory.hideShowStoreDetails(isHomeDelivery);
            if (!selectedStore && !isHomeDelivery) {
                $preferredStoreLink.trigger('click');
            }
        });

        $('.instore-delivery label').on('click', function() {
            var pid = $('input[name="pid"]').val();
            var storeId = User.storeId;
            $('.instore-delivery .delivery-option').trigger('click');
            if ($('.isavail').length < 1) {
                inventory.getStoreInventory(pid,storeId);
            }
        });

        var storeInfoWrapUnavail = $('.product-add-to-cart-wrap .store-info-wrap.unavailable');

        if (storeInfoWrapUnavail.length > 0 && !isQuickView) {
            //add unavailable class to ispu radio on pdp if selected store becomes unavailable
            //show the store info as well as the change store button to show user they can change it
            $(instoreDeliveryComponents).addClass('unavailable');
            $('.set-preferred-store, .store-info, .store-info-wrap').removeClass('hidden');
        }

        if ($('#product-content .delivery-option').length) {
            // set initial hidden input value for homeDelivery (boolean)
            if ($('#product-content .delivery-option:checked').val() == 'home') {
                $('input#homeDelivery').val(true);
            } else {
                $('input#homeDelivery').val(false);
            }
        }

        if (Number($('.inventoryAmt').val()) == 0 && $('#homeDelivery').val() == 'false' && $('#isMasterProduct').val() == 'false') {
            $('#delivery_option_home').trigger('click');
            setTimeout(function() {
                $('.store-info, .store-info-wrap, .set-preferred-store').removeClass('hidden');
                /*
                 * update swatch URLs to preserve the ISPU selection
                 * so inventory error is still displayed if another ISPU OOS variant is selected
                 * or In-Store method is selected if an ISPU available variant is selected
                 */
                $('.swatchanchor').each(function (){
                    var href = $(this).attr('href') + '&ispu=true';
                    $(this).attr('href', href);
                });
            }, 100);
        }
        productInventory.hideShowStoreDetails($('input#homeDelivery').val() == 'true');
    }
};

module.exports = productInventory;
