/**
* Description of the module and the logic it provides
*
* @module cartridge/js/homepopup
*/

'use strict';
var dialog = require('./dialog');
var util = require('./util');

var homepopup = {
    init: function () {
        var currentPage = window.location;
        var isConfirmation = currentPage.href.indexOf('confirmation') > -1;
        if (isConfirmation) {
            Cookies.set('confirmation', true, {expires: 30});
        }
        var fromConfirmation = Cookies.get('confirmation');
        var prevURL = document.referrer;
        var nextPage = prevURL.indexOf('madewell') > -1;
        var afterConfirmation = prevURL == '' && fromConfirmation;
        var mobile = util.isMobile();
        if ('pageContext' in window && 'ns' in window.pageContext) {
            if ((['cart','checkout','orderconfirmation'].indexOf(window.pageContext.ns) == -1) && (!mobile || mobile && nextPage || mobile && afterConfirmation)) {
                var url = window.Urls.homepopup,
                    isAnon = window.User.anonymous,
                    isAuth = window.User.authenticated;

                if ((isAnon && !Cookies.get('dismissRegister')) || (isAuth && !Cookies.get('dismissEmailSignUp'))) {
                    homepopup.emailLightboxFunction(url, true);
                }
            }
        }
    },
    emailLightboxFunction: function(url, cookiesEnabled) {
        var isAnon = window.User.anonymous;
        var timeout = util.isMobileSize ? 2000 : 0;
        var optinClass = isAnon ? 'optin-dialog anon' :'optin-dialog non';

        if (!cookiesEnabled) {
            if (isAnon) {
                Cookies.set('dismissRegister', false, {expires: 30});
            }
            Cookies.set('dismissEmailSignUp', false, {expires: 30});
        }

        setTimeout(function() {
            dialog.open({
                url: url,
                options: {
                    width: 500,
                    dialogClass: optinClass,
                    open: function () {
                        $(document).trigger('homepage:' + ($(this).find('#loyalty-form').size() > 0 ? 'register' : 'optin'));
                    },
                    close: function () {
                        $(this).dialog('close');
                        $('#wrapper').attr('aria-hidden', 'false');
                        if ($(this).find('#loyalty-form').size() > 0) {
                            Cookies.set('dismissRegister', true, {expires: 30});
                        } else if ($(this).find('#optin-form').size() > 0) {
                            Cookies.set('dismissEmailSignUp', true, {expires: 30});
                        }
                    }
                }
            });
        }, timeout);
        $(document).on('submit', '#LoyaltyRegistrationForm', homepopup.loyaltyForm);
        $(document).on('submit', '#OptinRegistrationForm', homepopup.optinForm);
    },
    loyaltyForm: function (e) {
        e.preventDefault();
        var form = $(this);
        if (form.valid()) {
            $.post(form.attr('action'), form.serialize(), function (data) {
                if (data && data.indexOf('registered-success') != -1) {
                    window.location.href = window.Urls.accountShow;
                } else {
                    $('#loyalty-form').replaceWith(data);
                }
            });
        }
    },
    optinForm: function (e) {
        e.preventDefault();
        var form = $(this);
        if (form.valid()) {
            $.post(form.attr('action'), form.serialize(), function (data) {
                $('#optin-form').replaceWith(data);
            });
        }
    },
    initEmailModalListener: function () {
        var url = window.Urls.homepopup;

        $('.home-lightbox-trigger').on('click', function() {
            homepopup.emailLightboxFunction(url, false);
        });
    }
};

$('body').on('click','.ui-widget-overlay', function() {
    $('.ui-dialog').filter(function () {
        return $(this).css('display') === 'block';
    }).find('.ui-dialog-content').dialog('close');
});

setTimeout(function() {
   //center dialog on window resize
    var winPosition = {top: '18%', left: '30%'};
    $(window).resize(function() {
        $('.ui-dialog').css(winPosition);
    });
}, 2200);

module.exports = homepopup;
