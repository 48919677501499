'use strict';

var initEventHandler = function () {
    $('.back-to-top').on('click', function () {
        $('body, html').animate({scrollTop: 0});
    });
};

var backToTop = function () {
    var isRendered = false;

    $(window).scroll(function () {
        var topOffset = window.pageYOffset;
        if (topOffset > 2799 && !isRendered) {
            $('.back-to-top').fadeIn();
            isRendered = true;
        } else if (topOffset < 2800 && isRendered) {
            isRendered = false;
            $('.back-to-top').fadeOut();
        }
    });
};

module.exports = function () {
    initEventHandler();
    backToTop();
};
