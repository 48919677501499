/* global DocumentTouch */

'use strict';

var quickview = require('./quickview');
var util = require('./util');

/**
 * @function
 * @description Sets up the quick view button when called with the appropriate values
 * @param {jQuery} $link - Link that is used to define the quick view button
 * @param {boolean} focus - Mark this as true when the focus event is used
 */
function initQuickView($link, focus) {
    var $qvButton = $('#quickviewbutton');

    if ($qvButton.length) {
        $qvButton.remove();
    }

    $qvButton = $('<div id="quickviewbutton"><a class="quickview button secondary">' + Resources.QUICK_VIEW + '</a></div>');

    $qvButton.on('click', function (e) {
        e.preventDefault();
        var $tile = $(this).closest('li[data-a]');
        var position = 0;
        $('.search-result-items li[data-a]').each(function (i) {
            if ($(this).data('a') == $tile.data('a')) {
                position = i;
                return false;
            }
        });
        var url = $link.attr('href');
        url = util.appendParamToURL(url, 'format', 'ajax');
        quickview.show({
            url: url,
            source: 'quickview',
            position: position
        });
        util.smartResize(function() {
            $('#QuickViewDialog').parent('.ui-dialog').position({
                my: 'center',
                at: 'center',
                collision: 'fit',
                of: window
            });
            if ($(this).dialog.isOpen === true) {
                $(this).dialog('close');
            }
        })

    });

    $qvButton.attr({
        'href': $link.attr('href'),
        'title': $link.attr('title')
    });

    $qvButton.insertBefore($link.next('.swatch-container'));

    if (focus) {
        $qvButton.css('display', 'block');
    }
}

/**
 * @function
 * @description Sets up the product tile so that when the mouse cursor enters the tile the quick view button appears
 */
var initQuickViewButtons = function () {
    $('.tiles-container .product-image').on('mouseenter', function () {
        var $link = $(this).find('.thumb-link');
        initQuickView($link);
    })
}

/**
 * @function
 * @description Sets up the product tile so that when the tile is focused with the keyboard the quick view button appears
 */
var initQuickViewButtonsFocus = function () {
    $('.tiles-container .product-image .thumb-link').on('focus', function () {
        var $link = $(this);
        initQuickView($link);
    });
}

/**
 * @function
 * @description Gets the wishlist status of a product
 */
var wishlistStatus = function ($tile, pid) {
    var url = util.appendParamsToUrl(Urls.wishlistStatus, {pid: pid}),
        $favoritesBtn = $tile.find('.add-to-favorites');
    if ($favoritesBtn.hasClass('logged')) {
        $.ajax({
            dataType: 'json',
            url: url,
            data: {
                format: 'ajax'
            }
        }).done(function (data) {
            if (data.inWishlist) {
                $favoritesBtn.addClass('added');
            }
        });
        return true;
    }
    return false;
};

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {

    // If touch events are available, disable the QuickViewButton initialization
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        initQuickViewButtons = function () {}
        initQuickViewButtonsFocus = function () {}
    } else {
        // If touch events are not available, make the product images hoverable
        $('.tiles-container .product-image').addClass('hoverable')
    }

    initQuickViewButtons();
    initQuickViewButtonsFocus();
    $(document).on('click', '.show-swatches', function () {
        var item = $(this);

        item.parent().find('.product-swatches').removeClass('hidden');
        item.hide();
    })

    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');
        if (typeof data !== 'undefined') {
            $thumb.attr({
                ['data-src']: data.src,
                ['data-srcset']: data.srcset,
                alt: data.alt,
                title: data.title
            });
        }
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var $altThumb = $tile.find('.product-image .thumb-link img').eq(1);
        var altData = $(this).children('img').filter(':first').data('alt');
        var currentAttrs = {
            ['data-src']: data.src,
            ['data-srcset']:data.srcset,
            alt: data.alt,
            title: data.title
        };
        var altAttrs = {
            ['data-src']: altData.src,
            ['data-srcset']:altData.srcset
        }; 
        $thumb.attr(currentAttrs);
        $altThumb.attr(altAttrs);
        $thumb.data('current', {
            alt: data.alt,
            title: data.title,
            src: data.src,
            srcset: data.srcset
        });
        //$altThumb.attr('src', data.altsrc);
        $tile.find('.add-to-favorites').data('colorid', data.colorid);

        $altThumb.bind('error', function() {
            $altThumb.attr('src', window.Urls.staticPath + 'images/noimagelarge.png');
        });
        $(this).blur();
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                alt: $thumb[0].alt,
                title: $thumb[0].title,
                src: $thumb[0].src,
                srcset: $thumb[0].srcset
            });
        }
        
        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            alt: data.alt,
            title: data.title,
            ['data-src']: data.src,
            ['data-srcset']: data.srcset
        });
        
        //$thumb.addClass('lazyload').removeClass('lazyloaded');
        $tile.find('.alternate-image').removeClass('visible');
        $tile.find('.primary-image').addClass('visible');
    });

    $('.tiles-container .product-image').on('keyup', function(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        var $self = $(this);
        //detect tab through
        if (code == 9) {
            if ($self.find('.alternate-image').length > 0) {
                $self.find('.primary-image').toggleClass('visible');
                $self.find('.alternate-image').toggleClass('visible');
            }
        }
    }).on('mouseenter', function () {
        var $tile = $(this).closest('.product-tile'),
            pid = $(this).parent().data('itemid');
        wishlistStatus($tile, pid);
    });
    $('.product-tile').on('mouseleave', function () {
        $(this).find('.add-to-favorites.added').removeClass('added');
    });
    if ($('.product-usd').length) {
        $('.product-usd').removeClass('hide-USD');
    }


    //Handle broken images on grid
    //If we overhaul imaging shot types we will want to remove these
    $('.thumb-link img').bind('error', function() {
        $(this).attr('src', window.Urls.staticPath + 'images/noimagelarge.png');
    });

}

exports.init = function () {
    var $tiles = $('.tiles-container .product-image');
    if ($tiles.length === 0) { return; }
    if ($('.product-listing').length === 0){
        initializeEvents();
    }
};
