'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    registration = require('./registration'),
    accordion = require('./product/accordion'),
    fluidconfigure = require('../fluidconfigure'),
    wlStatus = require('../wishlist_status');

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    tooltip.init();

    $form.on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val()
            });

            page.redirect(url);
        }
    });
    fluidconfigure.initOrderDetailsLineItems();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:first')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            page.redirect($(this).attr('href'));
        }
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
        .done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}
function initializePaymentForm() {
    var form = $('#CreditCardForm')
    $(form).on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });
    setTimeout(function () {
        validator.initForm(form)
    })
}

function initOrderHistoryAccordion () {
    $('.a11yAccordionItemHeader').first().addClass('order-history-active');
}

function initOrderDetailsEvents () {
    $('body').on('click', '.trackingnumber a', function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), '_blank');
    });
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initializeAddressForm();
    initAddressEvents();
    initPaymentEvents();
    initOrderHistoryAccordion();
    initOrderDetailsEvents();
    login.init();
    registration.init();
    wlStatus.init();
    accordion.init('.orders-accordion', orderDetails, hideOrder);
}

function orderDetails (item) {
    if (item) {
        var orderid = item.data('orderid');
        $.post(Urls.orderDetails, {orderNo: orderid}, function (data) {
            if (data) {
                item.find('.order-full-info').first().replaceWith(data);
            }
        })
    }

    // Remove class from any previous active accordion element
    $('.a11yAccordionItemHeader').removeClass('order-history-active');
    // Add active class to accordion header element
    $(item).siblings('.a11yAccordionItemHeader').addClass('order-history-active');
    var rowPosition = $(item).siblings('.a11yAccordionItemHeader').offset().top;
    if (window.pageYOffset > rowPosition) {
        setTimeout(function () {
            $('html,body').animate({scrollTop: ($(item).siblings('.a11yAccordionItemHeader').offset().top)}, 300);
        }, 100);
    }
}

function hideOrder (item) {
    // Remove active class for current accordion element
    if ($(item).siblings('.a11yAccordionItemHeader').hasClass('order-history-active')) {
        $(item).siblings('.a11yAccordionItemHeader').removeClass('order-history-active');
    }
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
    },
    initCartLogin: function () {
        login.init();
        registration.init();
    }
};

module.exports = account;
