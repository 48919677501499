'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    wlStatus = require('../wishlist_status');

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        loadSearchPage(loadingPlaceHolder);
    }
}

function loadSearchPage(loadingPlaceHolder) {
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    // switch state to 'loading'
    // - switches state, so the above selector is only matching once
    // - shows loading indicator
    loadingPlaceHolder.attr('data-loading-state', 'loading');
    loadingPlaceHolder.addClass('infinite-scroll-loading');


    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    var fillEndlessScrollChunk = function (html) {
        loadingPlaceHolder.removeClass('infinite-scroll-loading');
        loadingPlaceHolder.attr('data-loading-state', 'loaded');
        loadingPlaceHolder.parent('div#search-result-content').append(html);
    };

    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: gridUrl,
        success: function (response) {
            fillEndlessScrollChunk(response);
            productTile.init();
        }
    });
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, focusElement, isFilterBarOpened) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.search-result-content'));
    var refinementsOpened = $('.search-refinements-section').is(':visible');
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
        compareWidget.init();
        productTile.init();
        progress.hide();
        updateRefinementsState();
        history.pushState(undefined, '', url);

        // Focus the given element to make sure that focus is retained through the Ajax call
        if (focusElement) {
            $(focusElement).eq(0).focus();
        }

        if (isFilterBarOpened) {
            if (refinementsOpened) {
                $('.search-refinements-section').slideToggle();
            }
        } else {
            $('.search-refinements-section').slideToggle();
        }
        $('.loadMoreButton').off('click').on('click', function () {
            var $placeholder = $('.infinite-scroll-placeholder');
            loadSearchPage($placeholder);
            $(window).on('scroll', infiniteScroll);
            $('.loadMore-button').hide();
        });
        $('.back-to-top').trigger('click');
        if ($('.product-usd').length) {
            $('.product-usd').removeClass('hide-USD');
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle scroll of content anchor - issue with sticky
    $('.search-result-bookmarks a').on('click', function(e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: ($('#results-content').offset().top)-77}, 500);
    });

    // handle toggle refinement blocks
    $main.on('click', '.refinement > a.toggle, .refinement > h3', function (e) {
        e.preventDefault();
        if (!$('.search-refinements-section').length) {
            $(this).siblings('ul').toggle();
            if ($(this).hasClass('expanded')) {
                $(this).attr('aria-expanded', 'true');
            } else {
                $(this).attr('aria-expanded', 'false');
            }
        }
    });
    //set url for BV read review link
    $('body').on('click', '#QuickViewDialog #BVRRRatingSummaryLinkReadID a', function(){
        var url = $('.product-col-1 a').attr('href') + '#BVReviewsContainer';
        window.location.href = url;
    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();

        var focusElement = $(this).attr('id');

        if (focusElement) {
            focusElement = '#' + focusElement;
        }

        var isFilterBarOpened = false;
        if ($(this).hasClass('breadcrumb-relax')) {
            isFilterBarOpened = true;
        }
        if ($(this).hasClass('pagination-element')) {
            isFilterBarOpened = true;
        }
        updateProductListing(this.href, focusElement, isFilterBarOpened);
    });

    //grid layout change (2\4\6)
    $(document).on('click keypress', '.toggle-grid span', function(){
        var element = $(this),
            option = element.data('option'),
            url = window.location.href;
        url = util.removeParamFromURL(url, 'gridtype');
        url = util.appendParamToURL(url, 'gridtype', option);
        //quick fix for bad URL issue.  should eventually be fixed in util.
        if (url.indexOf('?&') != -1) {
            url = url.replace('?&','?');
        }

        history.pushState(undefined, '', url);

        $('#search-result-content').attr('class', option);
        $('.toggle-grid span').removeClass('active');
        Cookies.set('dw_gridviewpreference', option, {expires: 30});
        element.addClass('active');

        if (window.lazySizes) {
            window.lazySizes.autoSizer.checkElems();
        }
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        if (a.hasClass('swatch') || a.hasClass('quickview')) return true;
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        var isFilterBarOpened = true;
        updateProductListing($(this).find('option:selected').val(), '.sort-by select', isFilterBarOpened);
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            var isFilterBarOpened = true;
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl, '.items-per-page select', isFilterBarOpened);
        }
    });

    // Close Refinements on escape keyup
    $(document).keyup(function(e) {
        if (e.keyCode == 27 || e.keyCode == 13) {
            $('.search-refinements-section').slideToggle();
        }
    });

    /**
     * @listener
     * @description Listens to the mouseenter and mouseleave events on the product tile thumb-link to show/hide the primary and alternate images
     */
    $main.on('mouseenter touchstart', '.product-image', function () {
        // Hide the primary image and show the alternate image if one exists
        if (!util.isMobileSize()) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $(this).find('.primary-image').removeClass('visible');
                $altImage.addClass('visible');
            }
        }
    })
    .on('mouseleave touchend', '.product-image', function () {
        // Hide the alternate image and show the primary image if one exists
        if (!util.isMobileSize()) {
            var $altImage = $(this).find('.alternate-image[src]');
            if ($altImage.length) {
                $altImage.removeClass('visible');
                $(this).find('.primary-image').addClass('visible')
            }
        }
    });

    /**
     * @listener
     * @desc Listen for the click event on the refinement section toggle
     */
    $('body').on('click', '.refinement-select, .close-refinements', function () {
        $('.search-refinements-section').slideToggle();
    });

    /**
     * @listener
     * @desc Listen for the focus event and attach space and enter controls
     */
    $('body').on('focus', '.refinement-select, .close-refinements', function () {
        $(this).keyup(function(e) {
            //spacebar and enter click
            if (e.which === 32 || e.which === 13) {
                e.stopPropagation();
                $('.search-refinements-section').slideToggle();
            }
            //prevent scroll on space bar
            $(this).keydown(function(e) {
                if (e.which === 32) {
                    e.preventDefault();
                }
            });
        });
    });

     /**
      * @listener
      * @desc Listen for the blur on show more control and remove keyup events
      */
    $('body').on('blur', '.refinement-select, .close-refinements', function () {
        $(this).unbind('keyup');
        $(this).unbind('keydown');
    });


    /**
     * @listener
     * @desc Listen for the click event on the show hide link
     */
    $main.on('click', '#refinement-menu .refinement .show-more', function () {
        showHideRefinements($(this));
        updateRefinementsTabbing($(this));
    });

    /**
     * @listener
     * @desc Listen for the focus event and attach space and enter controls
     */
    $main.on('focus', '#refinement-menu .refinement .show-more', function() {
        $(this).keyup(function(e) {
            //spacebar and enter click
            if (e.which === 32 || e.which === 13) {
                e.stopPropagation();
                showHideRefinements($(this));
                updateRefinementsTabbing($(this));
            }

        });
        //prevent scroll on space bar
        $(this).keydown(function(e) {
            if (e.which === 32) {
                e.preventDefault();
            }
        });
    });

    /**
     * @listener
     * @desc Listen for the blur on show more control and remove keyup events
     */
    $main.on('blur', '#refinement-menu .refinement .show-more', function() {
        $(this).unbind('keyup');
        $(this).unbind('keydown');
    });

    $main.on('click', '.save-refinements button', function () {
        $('.search-refinements-section').slideToggle();
    });
    //@TODO uncomment in sprint 3
    //setProperHeight();
    //$(window).resize(function() {
    //    setProperHeight();
    //})

    /**
     * @listener
     * @desc Listen for the click event on the load more button
     */
    $('.loadMoreButton').click(function () {
        var $placeholder = $('.infinite-scroll-placeholder');
        sessionStorage.scrollCount = 0;
        loadSearchPage($placeholder);
        $(window).on('scroll', infiniteScroll);
        $('.loadMore-button').hide();
    });

    if ($('#positionInChunk').length && $('#scrollCount').length && $('#lazyLoadFirstPageSize').length) {
        var scrollCount = parseInt($('#scrollCount').val(), 10);
        var lazyLoadFirstPageSize = parseInt($('#lazyLoadFirstPageSize').val(), 10);
        var positionInChunk = parseInt($('#positionInChunk').val(), 10);
        var position = scrollCount * lazyLoadFirstPageSize + positionInChunk;
        if (positionInChunk > lazyLoadFirstPageSize) {
            position = positionInChunk;
        }
        var ourLi = jQuery($('.search-result-items li.grid-tile').eq(position));
        if (ourLi.length) {
            $([document.documentElement, document.body]).animate({
                scrollTop: ourLi.offset().top - 70
            }, 2000);
        }
    }
    $('body').on('click', 'li.grid-tile a', function() {
        try {
            var href = $(this).attr('href');
            var position = util.getUrlParameter(href, 'position');
            var activeChunk = util.getUrlParameter(href, 'activeChunk');
            if (position && activeChunk) {
                var url = window.location.href;
                url = util.removeParamFromURL(url, 'scrollCount');
                url = util.removeParamFromURL(url, 'positionInChunk');
                url = util.appendParamToURL(url, 'scrollCount', activeChunk);
                url = util.appendParamToURL(url, 'positionInChunk', position);
                history.replaceState(null,null, url);
            }
        } catch (e) {
            return true;
        }
    })
}
/**
 * Update refinements state (opened or closed)
 */
function updateRefinementsState() {
    var $secondary = $('#secondary');

    $secondary.find('.refinement ul').each(function() {
        $(this).find('li').each(function() {
            if ($(this).hasClass('selected') && $(window).innerWidth() >= util.getViewports('large')) {
                $(this).parents('.refinement').find('a.toggle').addClass('expanded').attr('aria-expanded', 'true');
                $(this).parents('.refinement').find('h3').addClass('expanded').attr('aria-expanded', 'true');
                $(this).parents('.refinement ul').show();
            }
        });
    });
}

/**
 * Shows/Hides the refinments
 */
function showHideRefinements(el) {
    var item = el,
        parent = item.parent().find('.hidden-items');
    parent.removeClass('visually-hidden');

    if (item.hasClass('displayed')) {
        item.text(Resources.SHOW_MORE);
        item.removeClass('displayed');
        parent.addClass('visually-hidden');
    } else {
        item.text(Resources.SHOW_LESS);
        item.addClass('displayed');
        parent.removeClass('visually-hidden');
    }
}

/**
 * Toggles tabindex so hidden refinements cannot be 'tabbed' through
 */

function updateRefinementsTabbing($el) {
    const $hiddenCntr = $el.parent().find('.hidden-items');
    if (!$hiddenCntr.hasClass('visually-hidden')) {
        $hiddenCntr.find('a').removeAttr('tabindex');
    } else {
        $hiddenCntr.find('a').attr('tabindex', '-1');
    }
}


exports.init = function () {
    compareWidget.init();
    productTile.init();
    initializeEvents();
    wlStatus.init();
};
