'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    progress = require('../progress'),
    quickview = require('../quickview'),
    fluidconfigure = require('../fluidconfigure'),
    util = require('../util'),
    cartStoreInventory = require('../storeinventory/cart');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('#cart-table').on('click', '.item-edit-details a:not(.personalized), .not-available a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });

    //override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {

        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        } else if (e.which === 13 && $(this).val().length >= 1) {
            e.preventDefault();
            if (window.s === undefined) {
                window.s = {};
            }
            $('#add-coupon').click();
        }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });
//    $('body').load('iframe', function() {
//        $('iframe').contents().find('.paypal-button-container')
//          .append($('<style type="text/css">  .paypal-button.paypal-button-color-silver{box-shadow:none !important;}  </style>'));
//    });

    try {
        var iFrameDom = $('iframe.xcomponent-component-frame').contents();
        iFrameDom.find('paypal-button').hide();
    } catch (e) {
        window.console.log('paypal iframe loading problem: '+e.message);
    }
    $('body').off('click','#add-coupon').on('click','#add-coupon', function (e){
        e.preventDefault();
        var $curObj = $(this),
            $form = $curObj.closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo($form);
        progress.show('#main');
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(response) {
                $('.ajax-refresh').html($(response).find('.ajax-refresh').html());
                initializeEvents();
                progress.hide();
            }
        });
    });
    $('select[name$="_quantity"]').off('change').on('change', function () {
        var $curObj = $(this);
        var qty = $curObj.val();
        if (!isNaN(qty)) {
            var row = $curObj.closest('tr.cart-row');
            var pid = row.find('.cart-pid').val();
            var url = Urls.updateQty;
            var recipeId = row.data('recipeid');
            var param = {
                pid: pid,
                Quantity: qty
            };
            param.uuid = row.data('uuid');
            param.format = 'ajax';

            var personalizedOptionValue = row.find('[name$="_isPersonalized"]');
            if (personalizedOptionValue.size() > 0) {
                param[personalizedOptionValue.first().attr('name')] = personalizedOptionValue.first().val();
            }
            if (recipeId) {
                param.recipeId = recipeId;
            }

            url = util.appendParamsToUrl(url, param);
            $.ajax({
                url : url,
                success : function (response){
                    $('.ajax-refresh').html($(response).find('.ajax-refresh').html());
                    $('body #mini-cart').html($(response).find('#mini-cart').html());
                    initializeEvents();
                }
            });
        }
    });
    $('select[name$="_state"]').on('change', function () {
        $(this).parent('select-style').removeClass('select-style-error');
    })

    $('.cart-row.full-out-of-stock').find('input.delivery-option').attr('disabled', 'true');

    fluidconfigure.initCartLineItems();
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
};
