/*eslint camelcase: [0, {properties: "never"}]*/
'use strict';

var event = require('../event');

var cart = {
    init: function (){
        //Cart Remove
        $('button[name$="deleteProduct"]').on('click', function() {
            var $cartRow = $(this).closest('tr'),
                upc = $cartRow.find('.cart-pid').val();
            cart.cartRemove(upc);
        });
        $('button[name$="checkoutCart"]').on('click', cart.checkoutStart);
        $('[name$="_quantity"]').on('change', function () {
            
            // event39
            // Cart Edit
            // Counter event to fire in the shopping cart whenver a user edits anything in their cart.
            event.pushEvent('event39');
        });
        
        $(document).on('click', '#add-coupon', function () {
            var couponCode = $('#dwfrm_cart_couponCode').val();
            
            // eVar112
            // Promo Code Value Entered
            // Passes the value a visitor used when hitting the apply button on the promo code field. 
            event.pushEVar('eVar112', couponCode);
            
            // event182
            // Promo Code Apply
            // Counter event to trigger every time a value is entered into the promo code field and a visitor clicks apply. This will always trigger with the Promo Code Value Entered eVar.
            event.pushEvent('event182');
        });
        
        // scView
        // Cart Views
        // Occurs each time a shopping cart is viewed.
        if ($('#dwfrm_login').size() > 0) {
            event.pushEvent('scCheckout', null, true);
            event.pushEvent('event18');
        } else {
            event.pushEvent('scView', null, false, {});
        }
    },

    cartRemove: function (upc) {
        
        // scRemove
        // Cart Removes
        // Occurs when a user removes an item from their cart.
        event.pushEvent('scRemove', null, true, {'eVar38': upc});
        
        // event39
        // Cart Edit
        // Counter event to fire in the shopping cart whenver a user edits anything in their cart.
        event.pushEvent('event39', null, true);
        event.push()
    },
    
    checkoutStart: function () {
        
        // scCheckout
        // Checkouts
        // Occurs each time a user initiates checkout.
        event.pushEvent('scCheckout');
    }
}

exports.init = cart.init;
