'use strict';

/**
 * @function
 * @description Initializes the accordion
 */
exports.init = function (parentSelector, onAreaShow, onAreaHide, rowIdexToOpen) {
    // if rowIdexToOpen != underfined
    // for PDP rowIdexToOpen = 0
    // for quickview modal = 10 to prevent open first item on window open
    if ($(parentSelector).length > 0) {
        if (!onAreaShow) {
            onAreaShow = function () {};
        }
        if (!onAreaHide) {
            onAreaHide = function () {};
        }
        if (!rowIdexToOpen) {
            rowIdexToOpen = 0;
        }
        var productAccordion = new A11yAccordion({ // eslint-disable-line
            parentSelector: parentSelector,
            showSearch: false,
            speed: 0,
            onAreaShow: onAreaShow,
            onAreaHide: onAreaHide
        });
        productAccordion.uncollapseRow(rowIdexToOpen);
        productAccordion.props.speed = 300;
    }
};
