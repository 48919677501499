'use strict';

var util = util = require('./util');
var catlanding = {
    /**
     * @function
     * @description Shows the given content on the Category Landing
     */
    init: function () {
        initPerfectFitLink()
        //Displays the first subcategories content in the list
        $('.subCategories-content div').first().addClass('active');
        $('.cat-landing-subCategories li').first().addClass('selected');
        //Displays subcategory content based on which li is clicked
        if ($('.category-landing-top').length > 0) {
            var tempClass = '';
            $('.cat-landing-subCategories li').on('click', function () {
                //Check to see if user is clicking on the current active content and either closing the mobile menu or preventing the content from hiding on desktop
                if ($(this).hasClass('selected')) {
                    if (util.isMobileSize()) {
                        $('.dropdown-menu').hide();
                        $('.dropdown-selector').removeClass('no-border');
                    }
                    return;
                } else {
                    tempClass = this.className;
                    $('.subCategories-content div').removeClass('active');
                    $('.cat-landing-subCategories li').removeClass('selected');
                    $(this).addClass('selected');
                    $('div.' + tempClass).toggleClass('active');
                    $('#selected').text($(this).text());
                }
                $('.cat-landing-subCategories li').hasClass('selected')
                //Mobile Menu Events
                if (util.isMobileSize()) {
                    $('.dropdown-menu').hide();
                    $('.dropdown-selector').removeClass('no-border');
                }
            })
        }
        //Mobile menu events
        $('.dropdown-selector').on('click', function () {
            $('.dropdown-menu').slideToggle();
            $('.dropdown-selector').toggleClass('no-border');
        })
        //Ensures Menu displays when user scrubs from mobile-desktop
        util.smartResize(function() {
            if ($(window).innerWidth() > util.getViewports('desktop')) {
                $('.dropdown-menu').show();
            }
        })
    }
}
function initPerfectFitLink() {
    $(document).on('click','#find-your-perfect-fit-wrapper-button' , function(e) {
        e.preventDefault();
        // ensure modal initializes on higher than 1025
        if ($(window).innerWidth() < util.getViewports('desktop')) {
            $('.fit-content').slideToggle();
            $(this).find('.down-caret').toggle();
            $(this).find('.close').toggle();
        } else {
            $('.fit-content').dialog({
                width: 1120,
                modal: true,
                close: function() {
                    $(this).dialog('destroy');
                }
            });
        }
    })
    //Ensures Content is hidden when user scrubs from mobile-desktop
    util.smartResize(function() {
        if ($(window).innerWidth() > util.getViewports('desktop') - 1) {
            $('.fit-content').hide();
            $('.fit-content').parent('.ui-dialog').position({
                my: 'center',
                at: 'center',
                collision: 'fit',
                of: window
            });
            $('#find-your-perfect-fit-wrapper button').removeClass('no-border');
        } else {
            $('.fit-content').dialog('close');
            $('.fit-content').hide();
        }
        if ($(this).dialog.isOpen === true) {
            $(this).dialog('close');
        }
    })
}

module.exports = catlanding;
