'use strict';

const minicart = require('./minicart'),
    searchsuggest = require('./searchsuggest'),
    util = require('./util'),
    tagmanager = require('./tagmanager'),
    homepopup = require('./homepopup');

const modules = {
    init: function () {
        if (window.SitePreferences.ENABLE_STICKY_HEADER) {
            initStickyHeader();
        }

        initCharacterLimit();
        initSearchSuggestions();
        initSecondaryNavigation();
        initToggle();
        initEmailSignup();
        initHeaderSearch();
        initMiniCart();
        initHeader();
        initNavigation();
        initQuantity();
        initMobileAccount();
        initMobileNavigation();
        homepopup.initEmailModalListener();
        setMicroSite();
    }
};

/**
 * @function
 * @desc Initializes the textarea character limit functionality
 */
function initCharacterLimit () {
    const controlKeys = ['8', '9', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    var limitReached = false;
    if ('pageContext' in window && 'ns' in window.pageContext) {
        tagmanager.init(window.pageContext.ns);
    }

    $('body').on('keydown', 'textarea[data-character-limit]', function (e) {
        const text = $.trim($(this).val()),
            charsLimit = $(this).data('character-limit'),
            charsUsed = text.length;

        if (((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) || e.which.toString() == '13' || limitReached && e.which.toString() != '8') {
            e.preventDefault();
        }
    }).on('change keyup', 'textarea[data-character-limit]', function () {
        var $textarea = $(this);
        var cursorPosition = $textarea.prop('selectionStart');
        var text = $textarea.val();
        var hasSpaceLast = false;
        if (text[text.length - 1] == ' ') {
            hasSpaceLast = true;
        }
        text = $.trim($textarea.val());
        text = text.replace(/\n/g, ' ');
        if (text.length > 160) {
            text = text.slice(0, 159);
        }
        var allWords = $.trim(text).split(' ');
        var currentLine = '';
        var tempLine = '';
        var lines = [];
        var newWords = [];
        var finalWords = [];
        for (var i = 0; i < allWords.length; i++){
            var currentWord = allWords[i];
            if (currentWord.length > 39){
                while (currentWord.length > 39) {
                    newWords.push(currentWord.slice(0, 39));
                    currentWord = currentWord.slice(40)
                }
                newWords.push(currentWord);
                if (finalWords.length) {
                    finalWords = finalWords.concat(newWords)
                } else {
                    finalWords = newWords;
                }
            } else {
                finalWords.push(currentWord);
            }
        }

        allWords = finalWords;
        var isLastWord = false;
        for (var j = 0; j < allWords.length; j++){
            if (j == allWords.length -1) {
                isLastWord = true;
            }
            var currentWord2 = allWords[j];
            if (currentWord2.length) {
                if (currentLine.length < 40) {
                    tempLine = (j == 0) ? currentWord2 : currentLine + ' ' + currentWord2;
                    if (tempLine.length > 39) {
                        lines.push($.trim(currentLine));
                        currentLine = (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                    } else {
                        currentLine += (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                    }
                } else {
                    lines.push($.trim(currentLine.slice(0, 39)));
                    currentLine = (isLastWord && !hasSpaceLast) ? currentWord2 : currentWord2 + ' ';
                }
            }
        }
        if (currentLine.trim().length > 0 && currentLine.trim().length < 40) {
            lines.push(currentLine);
        }
        var newTextareaValue = lines.slice(0,4).join('\r\n');
        $textarea.val(newTextareaValue);
        if (!(cursorPosition == 0 && ($(this).prop('selectionEnd') == $textarea.val().length))) {
            $(this).prop('selectionStart', cursorPosition);
            $(this).prop('selectionEnd', cursorPosition);
        }
    });

    // load js specific styles
    util.limitCharacters();
}

/**
 * @function
 * @desc Initializes the search suggestion functionality in the header
 */
function initSearchSuggestions () {
    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    const $searchContainer = $('#navigation .header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
}

/**
 * @function
 * @desc Initializes the secondary navigation functionality
 */
function initSecondaryNavigation () {
    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).next('ul').slideToggle('fast');
        $(this).children('span').toggleClass('rot180-svg');
    });
}

/**
 * @function
 * @desc Initializes the generic toggle functionality
 */
function initToggle () {
    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();

    $('.toggle').on('click', function () {
        if (!$(this).parents('.disable-toggle').length) {
            $(this).toggleClass('expanded').next('.toggle-content').toggle();
        }
    });
}

/**
 * @function
 * @desc Initializes the email signup functionality
 */
function initEmailSignup () {
    // subscribe email box
    const $subscribeEmail = $('.subscribe-email');

    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus(function () {
            const val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            const val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }
}
/**
 * @function
 * @desc Initializes the mini cart functionality
 */
function initMiniCart () {
    // Minicart focus
    minicart.setFocus();

    // Minicart  close button
    $('#close-minicart-btn').on('click', function() {
        minicart.close();
    });
}

/**
 * @function
 * @desc Initializes header search overlay functionality
 */
function initHeaderSearch () {

    // Launch search overlay
    $('.launch-search, .search-label').on('click', function() {
        $('.search-overlay').toggleClass('active');
        $('.mobile-close').addClass('search-open');
        $('#wrapper').addClass('no-scroll');
        $('nav').addClass('search-open-width');
        $('input#q').focus();
    });

    // Search  close button
    $('.search-close').on('click', function() {
        $('.search-overlay').removeClass('active');
        $('.mobile-close').removeClass('search-open');
        $('#wrapper').removeClass('no-scroll');
        $('nav').removeClass('search-open-width');
    });

    // Clear search field
    $('.clear-search-field').on('click', function() {
        $('#q').val('');
    });

}

/**
 * @function
 * @desc Initializes the misc. header functionality
 */
function initHeader () {
    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('html').toggleClass('menu-active');
        $('#find-your-perfect-fit-wrapper').toggleClass('menu-active');
    });

    $('.user-account').on('mouseenter', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parent('.user-info').addClass('active');
    });
    $('.user-account').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parent('.user-info').addClass('active');
    });
    $('.user-info').on('mouseleave', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).removeClass('active');
    });
    $('.user-info').on('keyup', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.keyCode == 27) {
            $(this).removeClass('active');
        }
    });

    $('.user-panel').on('click', function(e) {
        e.stopPropagation();
    });

    $('html').on('click', function() {
        $('.user-info').removeClass('active');
    });

    $(window).on('resize.header', function () {
        const mobileMenuBreakpoint = util.mediaBreakpointUp('mobile-menu');

        if (mobileMenuBreakpoint && $('html').hasClass('menu-active')) {
            $('html').removeClass('menu-active');
        }
    });
}

/**
 * @function
 * @desc Initializes the navigation functionality
 */
function initNavigation () {
    $('.menu-category .disabled-top-cat,.menu-category .menu-item-toggle:not(.menu-category .disabled-top-cat .menu-item-toggle)').on('click', function (e) {
        e.preventDefault();
        const $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active');
        $parentLi.toggleClass('active');
    });

    $('.mobile-nav-background').on('click', function() {
        $('html').removeClass('menu-active');
    });
}

/**
 * @function
 * @desc Initializes the quantity module events and DOM changes
 */
function initQuantity() {
    if ($('.quantity-module').length) {
        /**
         * @listener
         * @desc Listens for the click and keyup events on the quantity decrease button
         */
        $('body').on('click keyup', '.quantity-decrease', function (e) {
            if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
                const qtyInput = $(this).parents('.quantity-module').find('input'),
                    qtyInputValue = Number(qtyInput.val());

                // Only decrease the value if the current value is greater than 1
                if (qtyInputValue > 0) {
                    qtyInput.val(qtyInputValue - 1);
                    qtyInput.trigger('change');
                }
            }
        });

        /**
         * @listener
         * @desc Listens for the click and keyup events on the quantity increase button
         */
        $('body').on('click keyup', '.quantity-increase', function (e) {
            if (e.type === 'click' || (e.type === 'keyup' && e.which === 13)) {
                const qtyInput = $(this).parents('.quantity-module').find('input'),
                    qtyInputValue = Number(qtyInput.val()),
                    maxQtyValue = Number(qtyInput.attr('max'));

                // Only increase the value if the current value is less than the max value
                if (qtyInputValue < maxQtyValue) {
                    qtyInput.val(qtyInputValue + 1);
                    qtyInput.trigger('change');
                }
            }
        });

        /**
         * @listener
         * @desc Listens for the blur event on the quantity field
         */
        $('body').on('change', '.quantity-module input', function () {
            const qtyValue = Number($(this).val()),
                maxQtyValue = Number($(this).attr('max'));

            if (qtyValue < 0 || qtyValue == '') {
                $(this).val(1);
            } else if (qtyValue > maxQtyValue) {
                $(this).val(maxQtyValue);
            }
        });
    }
}

/**
 * @function
 * @desc Initializes the sticky header functionality
 * @returns
 */
function initStickyHeader () {
    // Setup the sticky header functionality
    var $window = $(window),
        lastWindowWidth = window.innerWidth,
        scrollTriggerDesktop = 19,
        scrollTriggerMobile = 75,
        toggleStickyHeader = function (e) {
            var windowWidth = window.innerWidth;

            // Only true if the event came from a trigger call, if the event is of type
            // scroll, or only the width changed when the resize event occurs
            if (e.isTrigger === 3 || e.type === 'scroll' || lastWindowWidth !== windowWidth) {
                var scrollTop = $window.scrollTop(),
                    desktopQuery = util.mediaBreakpointUp('md'),
                    scrollTrigger;

                if (desktopQuery) {
                    scrollTrigger = scrollTriggerDesktop;
                } else {
                    scrollTrigger = scrollTriggerMobile;
                }

                if (scrollTop > scrollTrigger && !$('html').hasClass('sticky')) {
                    $('html').addClass('sticky');
                } else if (scrollTop <= scrollTrigger) {
                    $('html').removeClass('sticky');
                }

                lastWindowWidth = windowWidth;
            }
        };

    /**
     * @listener
     * @description Listens for the scroll and resize events on the window to toggle the sticky header when appropriate
     */
    $window.on('scroll.sticky resize.sticky', toggleStickyHeader).trigger('resize.sticky');
}
/**
 * @function
 * @desc Initializes the mobile footer functionality
 */
function initMobileFooter() {
    if ($(window).innerWidth() < util.getViewports('desktop')) {
        $('.footer-item h3').unbind('click').on('click', function () {
            $(this).children('span').toggleClass('rot180-svg');
            $(this).next('.menu-footer').slideToggle('fast');
            $(this).toggleClass('no-border');
        });
    }
    util.move(util.isMobileSize(), '.footer-selectors', '#footer-item-last', '#footer-item-first');
    if ($(window).innerWidth() > util.getViewports('desktop')) {
        $('.menu-footer').show();
    }
}
/**
 * @function
 * @desc Initializes the account mobile functionality
 */
function initMobileAccount() {
    if ($(window).innerWidth() < util.getViewports('desktop')) {
        $('.mobile-toggle').unbind('click').on('click', function () {
            $(this).children('span').toggleClass('rot180-svg');
            $(this).next('.account-mobile-nav').slideToggle('fast');
            $(this).next('.account-mobile-nav').toggleClass('add-border');
            $(this).toggleClass('no-border');
        });
    }
    util.move(util.isDesktopSize(), '.secondary-navigation', '.account-welcome-header', '#secondary');
    if ($(window).innerWidth() > util.getViewports('desktop')) {
        $('.account-mobile-nav').show();
    }
}
util.smartResize(function() {
    initMobileFooter();
    initMobileAccount();
});

/**
 * @function 
 * @desc initializes the mobile navigation to refresh the nav links using an ajax call
 * on click of the mens or womens microsite tabs.
 */
function initMobileNavigation() {
    var $mobileNavigationContainer = $('#navigation .menu-category-container');
    $('body').on('click', '#navigation .header-microsite-tabs a', function(e) {
        e.preventDefault();
        //No need to load, click is done on active tab
        if ($(this).hasClass('active')) {
            return true;
        }
        var dataMenu = $(this).attr('data-menu');
        //Synchronize "active" state of #navigation and top level microsite-tabs
        $('.header-microsite-tabs a').removeClass('active');
        $('.header-microsite-tabs a[data-menu="' + dataMenu + '"]').addClass('active');
        var url = util.appendParamToURL(Urls.mobilemenu, 'cgid', dataMenu);
        $.ajax({
            url: url,
            success: function(result){
                $mobileNavigationContainer.html(result);
                initNavigation();
            }
        });
    });
}

/**
 * @function 
 * @desc initializes the mobile navigation to refresh the nav links using an ajax call
 * on click of the mens or womens microsite tabs.
 */
function setMicroSite() {
    $('.header-microsite-tabs a').on('click', function() {
        var microSite = $(this).data('menu');
        var url = util.appendParamToURL(Urls.setMicroSite, 'microSiteId', microSite);
        $.ajax({
            url: url
        });
    });
}

module.exports = modules;
