'use strict';

var _ = require('lodash'),
    dialog = require('../dialog'),
    TPromise = require('promise'),
    util = require('../util'),
    page = require('../page');

var newLine = '\n';
var storeTemplate = function (store, selectedStoreId, selectedStoreText) {
    return [
        '<li class="store-tile ' + store.storeId + (store.storeId == selectedStoreId ? ' selected' : '') + (store.status === 'NA' ? ' unavailable' : '') + '">',
        '    <p class="store-name">' + store.name + '</p>',
        '    <p class="store-address">',
        '        ' + store.address1 + '<br/>',
        '        ' + store.city + ', ' + store.stateCode + ' ' + store.postalCode + '<br/>',
        '        <span class="distance-' + store.storeId + '"</span>',
        '    </p>',
        '    <p class="store-status '+ store.status +'">' + Resources['ISPU_' + store.status] + '</p>',
        '    <button class="select-store-button ' + (store.storeId == selectedStoreId ? ' selected-store' : '') + '" data-store-id="' + store.storeId + '"' +
        (store.statusclass !== 'store-in-stock' ? '' : '') + (store.status === 'NA' ? 'disabled="disabled"' : '') + '>',
        '        ' + (store.storeId == selectedStoreId ? selectedStoreText : Resources.SELECT_STORE),
        '    </button>',
        '</li>'
    ].join(newLine);
};

var storeListTemplate = function (stores, selectedStoreId, selectedStoreText, pid) {
    if (stores && stores.length) {
        stores = stores.length > 6 ? stores.slice(0,6) : stores;
        var oneStoreMsg = window.User.storeId ? Resources.ISPU_ONE_STORE_ONLY : '';
        return [
            '<div id="preferred-store-panel">',
            '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
            '     <div class="zip-retry-form-row">',
            '     <div class="zip-retry-inpu-wrapper">',
            '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
            '           <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
            '     </div>',
            '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
            '     </div>',
            '<p class="single-store-msg">' +oneStoreMsg + '</p>',
            '</div>',
            '<div class="store-list-container">',
            '<ul class="store-list">',
            _.map(stores, function (store) {
                return storeTemplate(store, selectedStoreId, selectedStoreText);
            }).join(newLine),
            '</ul>',
            '</div>',
            '<div class="store-list-pagination">',
            '</div>'
        ].join(newLine);
    } else if (User.previousStores && User.previousStores.length) {
        return retryWithStoresTemplate(User.previousStores, selectedStoreId, selectedStoreText);
    } else {
        return retryTemplate();
    }
};

var retryWithStoresTemplate = function(stores, selectedStoreId, selectedStoreText) {
    var prevStores = stores.length > 6 ? stores.slice(0,6) : stores;
    var oneStoreMsg = window.User.storeId ? Resources.ISPU_ONE_STORE_ONLY : '';
    return  [
        '<div id="preferred-store-panel" class="zip-retry">',
        '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
        '     <div class="zip-retry-form-row">',
        '     <div class="zip-retry-inpu-wrapper">',
        '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
        '     </div>',
        '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
        '     </div>',
        '</div>',
        '<div class="no-results">' + Resources.TRY_ANOTHER_ZIP + '</div>',
        '<p class="single-store-msg">' + oneStoreMsg + '</p>',
        '<div class="store-list-container">',
        '<ul class="store-list">',
        _.map(prevStores, function (store) {
            return storeTemplate(store, selectedStoreId, selectedStoreText);
        }).join(newLine),
        '</ul>',
        '</div>'
    ].join(newLine);
}

var retryTemplate = function(message) {
    message = message || Resources.TRY_ANOTHER_ZIP
    return  [
        '<div id="preferred-store-panel" class="zip-retry">',
        '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
        '     <div class="zip-retry-form-row">',
        '     <div class="zip-retry-inpu-wrapper">',
        '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
        '     </div>',
        '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
        '     </div>',
        '</div>','<div class="no-results">' + message + '</div>'
    ].join(newLine);
}

var zipPromptTemplate = function () {
    return [
        '<div id="preferred-store-panel">',
        '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
        '      <p>' + Resources.ENTER_ADDRESS_INFO + '</p>',
        '    <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
        '</div>'
    ].join(newLine);
};

var showServiceError = function () {
    var $container = $('.store-list-container'),
        message = Resources.TRY_LATER,
        messageHtml = '<div class="no-results">' + message + '</div>'
    $container.removeClass('.loading-template');
    $container.empty().append(messageHtml);
    storeinventory.checkStoreSelection();
}

/**
 * @description test whether zipcode is valid for either US or Canada
 * @return {Boolean} true if the zipcode is valid for either country, false if it's invalid for both
 **/
var validateZipCode = function (zipCode) {
    var regexes = {
            canada: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
            usa: /^\d{5}(-\d{4})?$/
        },
        valid = false;
    if (!zipCode) {
        invalidZipMessage();
        return valid;
    }
    _.each(regexes, function (re) {
        var regexp = new RegExp(re);
        valid = regexp.test(zipCode);
    });
    if (!valid) {
        invalidZipMessage();
    }
    return valid;
};

function invalidZipMessage() {
    $('#preferred-store-panel').parent().find('.no-results').remove()
    $('<div class="no-results">' + Resources.INVALID_ZIP + '</div>').insertAfter($('.zip-retry-form-row'));
}

function getStoreDistance(customerLocation, store) {
    if (!customerLocation || !store) {
        return;
    }
    var DistanceMatrixService = new window.google.maps.DistanceMatrixService();
    DistanceMatrixService.getDistanceMatrix({
        origins: [customerLocation.lat + ',' + customerLocation.long],
        destinations: [store.lat + ',' + store.long],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false
    }, function (results, status) {
        if (status == 'OK' && results) {
            var distanceObj = results.rows[0].elements[0].distance;
            var miles = String(distanceObj.text).split(' ')[0],
                roundedMiles = Math.round(Number(miles)),
                distanceMsg = String(roundedMiles) + ' Miles Away';
            $('.distance-' + store.storeId).text(distanceMsg);
        }
    });
}

var storeinventory = {
    setPreferredStore: function (storeId) {
        User.storeId = storeId;
        $.ajax({
            url: Urls.setPreferredStore,
            type: 'POST',
            data: {storeId: storeId}
        });
        $('#checkStoreInventory').show();
    },
    zipPrompt: function (callback) {
        var self = this;
        dialog.open({
            html: zipPromptTemplate(),
            options: {
                //title: Resources.STORE_NEAR_YOU,
                width: 764,
                buttons: [{
                    text: Resources.SEARCH,
                    click: function () {
                        var zipCode = $('#user-zip').val();
                        if (validateZipCode(zipCode)) {
                            self.setUserZip(zipCode);
                            if (callback) {
                                callback(zipCode);
                            }
                        }
                    }
                }],
                open: function () {
                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                    });
                }
            }
        });
    },
    zipRetry: function (callback, pid, selectedStoreText, selectedStoreId) {
        var self = this;
        dialog.open({
            html: User.previousStores && User.previousStores.length ? retryWithStoresTemplate(User.previousStores, selectedStoreId, selectedStoreText) : retryTemplate(),
            options: {
                dialogClass: 'ispu-zip-retry',
                width: 'auto',
                buttons: [{
                    click: function () {
                        var zipCode = $('#user-zip').val();
                        if (validateZipCode(zipCode)) {
                            self.setUserZip(zipCode);
                            if (callback) {
                                callback(zipCode);
                                dialog.close();
                            }
                        }
                    }
                }],
                open: function () {
                    self.updateZipcodeField();
                    $('.zip-retry-form-row button').on('click', function() {
                        $('.ui-dialog-buttonset .ui-button').trigger('click');
                    });

                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                    });

                    $('.select-store-button').on('click', function (e) {
                        e.preventDefault();
                        var storeId = $(this).data('storeId');

                        // if the store is already selected, don't select again
                        if (storeId === selectedStoreId) { return; }
                        $('.store-list .store-tile.selected').removeClass('selected')
                            .find('.select-store-button').text(Resources.SELECT_STORE);
                        $(this).text(selectedStoreText)
                            .closest('.store-tile').addClass('selected');

                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');

                        if (window.pageContext.ns == 'cart') {
                            var $plis = $('.cart-row');
                            $plis.each(function() {
                                pid = $(this).find('a.set-preferred-store').data('pid');
                                storeinventory.updateFooterInfo(newStoreId, pid);
                            });
                        } else {
                            var isMonogrammed = false;
                            var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
                            if (personalizedOption != null && typeof personalizedOption != 'undefined') {
                                if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                                    isMonogrammed = true;
                                }
                            }
                            storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                            self.setAddToCartAttributes(newStoreId, User.previousStores);
                        }
                        dialog.close();
                    });
                }
            }
        });

    },
    getStoresInventory: function (pid) {
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, {
                pid: pid,
                zipCode: User.zip
            }),
            dataType: 'json'
        }));
    },
    getStoreInventory: function (pid,storeId) {
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.getStoreInventory, {
                pid: pid,
                storeId: storeId
            }),
            dataType: 'json'
        })).then(function (data) {
            if (data && data.status) {
                var statusMsg = '<div class="isavail ' +data.status+ '" data-status="' +data.status+ '">' +Resources['ISPU_' + data.status]+ '</div>'
                $('#checkStoreInventory').hide();
                $('.store-info-wrap').append(statusMsg);
            }
        });
    },
    updateZipcodeField: function () {
        var zipcode = null
        $.ajax({
            type: 'GET',
            url: Urls.getZipCode,
            success: function (data) {
                // if no data object is included in the response, return here to prevent JS errors
                if (!data || !data[0]) { return; }
                zipcode = data[0].zip;
                $('input#user-zip').val(zipcode);
                if (zipcode == null || typeof zipcode == 'undefined') {
                    $.ajax({
                        type: 'GET',
                        url: Urls.getGeolocation,
                        success: function (data) {
                            zipcode = data.location.zip;
                            $('input#user-zip').val(zipcode);
                        }
                    });
                }
            }
        });
    },
    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     **/
    selectStoreDialog: function (options) {
        var self = this;
        var stores = options.stores,
            selectedStoreId = options.selectedStoreId,
            selectedStoreText = options.selectedStoreText,
            pid = options.pid,
            storeList = storeListTemplate(stores, selectedStoreId, selectedStoreText, pid);
        if (stores && stores.length) {
            if (stores.indexOf('empty') > -1) {
                dialog.open({
                    html: retryTemplate(Resources.TRY_LATER),
                    options: {
                        height: 'auto'
                    }
                });
                return;
            }
            $(stores).each(function() {
                getStoreDistance({lat: options.lat, long: options.long}, this)
            }).promise()
                .done(function() {
                    dialog.open({
                        html: storeList,
                        options: {
                            //title: Resources.SELECT_STORE + ' - ' + User.zip,
                            modal: true,
                            width: 'auto',
                            maxWidth: '900',
                            dialogClass: 'ispu-select-store',
                            close: function () {
                                var isPDP = $('.pt_product-details').length;
                                storeinventory.checkStoreSelection();
                                if ('ApplePaySession' in window && !isPDP) {
                                    // we need to refresh in order to update the Apple Pay session based on delivery option selection
                                    page.refresh();
                                }
                            },
                            open: function () {
                                $('.select-store-button').on('click', function (e) {
                                    e.preventDefault();
                                    var storeId = $(this).data('storeId');
                                    // if the store is already selected, don't select again
                                    if (storeId === selectedStoreId) { return; }
                                    $('.store-list .store-tile.selected').removeClass('selected')
                                        .find('.select-store-button').text(Resources.SELECT_STORE);
                                    $(this).text(selectedStoreText)
                                        .closest('.store-tile').addClass('selected');
                                    if (options.selectStoreCallback) {
                                        options.selectStoreCallback(storeId);
                                    }
                                    if (options.continueCallback) {
                                        options.continueCallback();
                                    }
                                    var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                                    if (window.pageContext.ns == 'cart') {
                                        var $plis = $('.cart-row');
                                        $plis.each(function() {
                                            var isMonogrammed = $(this).hasClass('personalized');
                                            pid = $(this).find('a.set-preferred-store').data('pid');
                                            storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                                        });
                                    } else {
                                        var isMonogrammed = false;
                                        var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
                                        if (personalizedOption != null && typeof personalizedOption != 'undefined') {
                                            if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                                                isMonogrammed = true;
                                            }
                                        }
                                        storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                                        self.setAddToCartAttributes(newStoreId, stores);
                                    }
                                    if (User.storeId) {
                                        $('.set-preferred-store a, a.set-preferred-store').html('Change Store');
                                    }
                                    dialog.close();
                                });
                                $('.search-by-user-zip').on('click', function () {
                                    var zipCode = $('#user-zip').val();
                                    var loadingTemplate = function (stores, selectedStoreId, selectedStoreText, pid) {
                                        return [
                                            '<div id="preferred-store-panel">',
                                            '     <h3>' + Resources.STORE_NEAR_YOU + '</h3>',
                                            '     <div class="zip-retry-form-row">',
                                            '     <div class="zip-retry-inpu-wrapper">',
                                            '           <input type="text" id="user-zip" placeholder="' + Resources.ENTER_ZIP + '" name="zipCode"/>',
                                            '           <input type="hidden" id="ispu-pid" name="ispu-pid" value="' +pid+ '"/>',
                                            '     </div>',
                                            '     <button class="search-by-user-zip">' + Resources.SEARCH_ANOTHER_ZIP + '</button>',
                                            '     </div>',
                                            '</div>',
                                            '<div class="store-list-container loading-template">',
                                            '    <div class="ispu-loader">',
                                            '    </div>',
                                            '</div>',
                                            '<div class="store-list-pagination">',
                                            '</div>'
                                        ].join(newLine);
                                    };
                                    
                                    var loadingOptions = {
                                        modal: true,
                                        width: 'auto',
                                        maxWidth: '900',
                                        height: '670',
                                        dialogClass: 'ispu-select-store'
                                    };
                                    if (validateZipCode(zipCode)) {
                                        User.previousStores = stores;
                                        dialog.open({
                                            html: loadingTemplate,
                                            options: loadingOptions
                                        });
                                        self.setUserZip(zipCode);
                                        self.selectStore(pid);
                                    }
                                });


                                $('.search-new-zip button').on('click', function() {
                                    var zipCode = $('#user-zip').val();
                                    if (validateZipCode(zipCode)) {
                                        self.setUserZip(zipCode);
                                        if (options.reSearchCallback) {
                                            dialog.close();
                                            options.reSearchCallback(zipCode);
                                        }
                                    }
                                });

                                $('#user-zip').on('keypress', function (e) {
                                    if (e.which === 13) {
                                        // trigger the search button
                                        var zipCode = $('#user-zip').val();
                                        if (validateZipCode(zipCode)) {
                                            self.setUserZip(zipCode);
                                            if (options.reSearchCallback) {
                                                dialog.close();
                                                options.reSearchCallback(zipCode);
                                            }
                                        }
                                    }
                                });
                                $('#user-zip').on('keypress', function (e) {
                                    if (e.which === 13) {
                                        // trigger the search button
                                        $('.search-by-user-zip').trigger('click');
                                    }
                                });
                                // on cart page, populate the hidden input field with the pid for use when searching by another zip code
                                if (typeof pid != 'undefined') {
                                    $('.pt_cart input#pid').val(pid);
                                }

                                self.updateZipcodeField();
                            }
                        }
                    });
                });
        } else {
            self.zipRetry(function() {
                storeinventory.selectStore();
            }, pid, selectedStoreText, selectedStoreId);
        }
    },
    setUserZip: function (zip) {
        User.zip = zip;
        $.ajax({
            type: 'POST',
            url: Urls.setZipCode,
            data: {
                zipCode: zip
            }
        });
    },
    shippingLoad: function () {
        var $checkoutForm = $('.address');
        $checkoutForm.off('click');
        $checkoutForm.on('click', 'input[name$="_shippingAddress_isGift"]', function () {
            $(this).parent().siblings('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val());
        });
    },
    setAddToCartAttributes: function (storeId, stores) {
        var store;
        for (var i=0; i < stores.length; i++) {
            store = stores[i];
            if (store.storeId == storeId) {
                var availStatus = store.status.indexOf('STS') > -1 ? 'false' : 'true';
                $('#availableInStore').val(availStatus);
                $('#fromStoreId').val(store.storeId);
                if (store.status == 'SDP') {
                    $('#pickupDay').val('today');
                } else if (store.status == 'SDP_tomorrow') {
                    $('#pickupDay').val('tomorrow');
                } else {
                    $('#pickupDay').val('');
                }
                break;
            }
        }
    },
    updateFooterInfo: function (storeID, pid, isMonogrammed) {
        var url = window.Urls.storeInfoFooter;
        if (storeID) {
            url += '?storeID=' + storeID;
            if (pid) {
                url += '&pid=' + pid;
            }
            if (isMonogrammed) {
                url += '&isMonogrammed=true'
            }
        }

        if (window.pageContext.ns == 'product') {
            url = util.appendParamToURL(url, 'location', 'pdp');
        }
        $.get(url, function (data) {
            if (window.pageContext.ns == 'cart') {
                var $pli = $('a.set-preferred-store[data-pid="' +pid+ '"]').closest('tr'),
                    isHomeDelivery = $pli.find('.delivery-option:checked').val() == 'home';

                $pli.find('.store-info-wrap').replaceWith(data);
                if (isHomeDelivery) {
                    $pli.find('.selected-store-address, .selected-store-name, .isavail').addClass('hidden');
                }
                /*
                 * if this lineitem is not disabled for ISPU,
                 * remove the disabled attributes and classes form the In Store delivery option
                 * if this lineitem was previously selected for ISPU, attempt to restore that selection
                 */
                if ($pli.find('input[name="lineIsPickupDisabled"]').val() == 'false') {
                    $pli.find('.instore-delivery').removeClass('unavailable');
                    $pli.find('input[value="store"]').removeAttr('disabled').removeClass('unavailable');
                    if ($pli.find('input[name="lineAtCapacityFlag"]').val() == 'true') {
                        $pli.find('input[value="store"]').trigger('click');
                    }
                }
            } else {
                $('.store-info-wrap').replaceWith(data).addClass('hidden');
                $('.add-to-cart-wrap').addClass('no-flex');
                $('.delivery-options-wrap').removeClass('hidden');
                $('.availability-message-in-store-pickup').hide();
            }
        });

        if (window.pageContext.ns == 'product') {
            if (url.indexOf('?') != -1) {
                url += '&';
            } else {
                url += '?';
            }
            url += 'pid=' + $('#pid').val();
            $.get(url, function (data) {
                if ($('#pdpMain').find('.store-info-wrap').length == 0) {
                    $('#pdpMain').find('.in-store-pickup-error').after('<div class="store-info-wrap"></div>');
                }
                $('#pdpMain').find('.store-info-wrap').replaceWith(data);
                $('.ispuCapacityMessage:not(".hidden")').addClass('hidden');
            }).done(function(){
                $('#checkStoreInventory').on('click', function(e) {
                    e.preventDefault();
                    var pid = $('#pid').val();
                    var storeId = User.storeId;
                    storeinventory.getStoreInventory(pid,storeId).done(function() {
                        if ($('.isavail').length < 1) {
                            $('.ispu-service-error').text(Resources.TRY_LATER);
                        }
                    });
                });
            });
        }
    },
    selectStore: function (reSearchPid) {
        var self = this;
        var isMonogrammed = false;
        var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
        if (personalizedOption != null && typeof personalizedOption != 'undefined') {
            if ($('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes') {
                isMonogrammed = true;
            }
        }
        var pid = $('.pt_cart').length ? $('input#ispu-pid').val() : $('input#pid').val();
        if (reSearchPid) {
            pid = reSearchPid;
        }
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({'address': User.zip}, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK && results[0].geometry.location) {
                var location = results[0].geometry.location;
                var params = {
                    lat: location.lat(),
                    long: location.lng(),
                    pid: pid,
                    isMonogrammed: isMonogrammed
                };
                $.ajax({
                    url: util.appendParamsToUrl(Urls.storesInventory, params),
                    dataType: 'json',
                    success: function (stores) {
                        storeinventory.selectStoreDialog({
                            stores: stores,
                            selectedStoreId: User.storeId,
                            selectedStoreText: Resources.PREFERRED_STORE,
                            pid: pid,
                            lat: params.lat,
                            long: params.long,
                            continueCallback: function () {
                                self.setAddToCartAttributes(User.storeId, stores);
                            },
                            reSearchCallback: function () {
                                storeinventory.selectStore();
                            },
                            selectStoreCallback: function () {
                                var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                                storeinventory.updateFooterInfo(newStoreId, pid, isMonogrammed);
                                self.setAddToCartAttributes(newStoreId, stores);
                                self.setPreferredStore(newStoreId);
                            }
                        });
                        //if store is unavailable disable its select-store-button
                        $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
                    },
                    error: function() {
                        storeinventory.selectStoreDialog({
                            stores: [],
                            selectedStoreId: User.storeId,
                            selectedStoreText: Resources.PREFERRED_STORE,
                            pid: pid
                        });
                    }
                })
            } else {
                storeinventory.selectStoreDialog({
                    stores: [],
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    pid: pid
                });
            }
        });
    },
    showStoresByGeolocation: function (lat, long, pid, personalized) {
        var self = this;
        var isMonogrammed;
        var personalizedOption = $('[data-optionid="isPersonalized"]').find(':selected').attr('value');
        if (personalizedOption != null && typeof personalizedOption != 'undefined') {
            isMonogrammed = personalized ? personalized : $('[data-optionid="isPersonalized"]').find(':selected').attr('value').toLowerCase() == 'yes';
        } else {
            isMonogrammed = personalized ? personalized : false;
        }
        var params = {
            lat: lat,
            long: long,
            pid: pid,
            isMonogrammed: isMonogrammed,
            shipToStore: true
        };

        $.ajax({
            url: util.appendParamsToUrl(Urls.loadByLatLong, params),
            dataType: 'json',
            success: function (stores) {
                storeinventory.selectStoreDialog({
                    stores: stores,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    pid: pid,
                    lat: params.lat,
                    long: params.long,
                    selectStoreCallback: function () {
                        var newStoreId = $('.store-tile.selected').find('.select-store-button').data('store-id');
                        storeinventory.updateFooterInfo(newStoreId, pid, params.isMonogrammed);
                        self.setAddToCartAttributes(newStoreId, stores);
                        self.setPreferredStore(newStoreId);
                        $('.change-store').removeClass('hidden');
                    }
                });
                //if store is unavailable disable its select-store-button
                $('.store-tile.unavailable').find('.select-store-button').attr('disabled', 'disabled');
            },
            error: function () {
                return showServiceError();
            }
        });
    },
    checkStoreSelection: function () {
        var isStoreSelected = User.storeId,
            isPDP = $('.pt_product-details').length;
        if (!isStoreSelected) {
            // if no store is selected, change selection to home delivery
            $('input[value="home"]').prop('checked', true);
            if (isPDP) {
                $('a.set-preferred-store').addClass('hidden');
                $('.swatchanchor').each(function (){
                    var href = $(this).attr('href').replace(/&ispu=true/g,'');
                    $(this).attr('href', href);
                });
            }
        } else {
            $.ajax({
                url: Urls.setStorePickup
            });
        }
    },
    init: function () {
        $('.footer-container').on('click', '.preferred-store', function (e) {
            e.preventDefault();
            if (!User.zip) {
                storeinventory.zipPrompt(function () {
                    storeinventory.selectStore();
                });
            } else {
                storeinventory.selectStore();
            }
        });
        $('body').on('click', function(){
            var widgetOverlay = $('.ispu-select-store + .ui-widget-overlay').length;
            if (widgetOverlay > 0) {
                $('body').addClass('dialog-open');
            } else {
                $('body').removeClass('dialog-open');
            }
        });
        var sizeSelected = $('.size .selectable.selected').length;
        var isPDP = $('.pt_product-details').length;
        if (isPDP && !sizeSelected > 0) {
            $('.change-store').addClass('hidden');
        }
        //add a class for the error message so it can be repositioned if there is no store/address on the pdp
        var addressText = $('.selected-store-address').text();
        if (addressText == '') {
            $('.in-store-pickup-error').addClass('withoutAddress');
            $('.change-store').addClass('hidden');
        }
        // notAvailForIspuMessage
        $('.notAvailForIspuMessage').each(function() {
            var notEligible = $(this),
                $cartRow = $(this).closest('tr');
            if (notEligible.length > 0) {
                $cartRow.find('.instore-delivery').addClass('notEligible');
            }
        })
    }
};

module.exports = storeinventory;
