'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    fluidconfigure = require('../../fluidconfigure'),
    progress = require('../../progress'),
    util = require('../../util');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    var selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();


    if (selectedPaymentMethodID == 'SA_SILENTPOST') {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth);
        $creditCard.find('[name$="_year"]').val(data.expirationYear);
    } else {
        $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger(
                'change');
        $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger(
                'change');
    }

    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID, selectedPaymentMethod) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            switch (selectedPaymentMethod) {
                case 'SA_REDIRECT':
                    $('.payment-method-expanded .saCCToken .field-wrapper').val(
                            data.selectedCardID);
                    $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID')
                            .val(data.selectedCardID);
                    break;
                case 'SA_IFRAME':
                    $('.payment-method-expanded .saIframeCCToken .field-wrapper')
                            .val(data.selectedCardID);
                    $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID')
                            .val(data.selectedCardID);
                    break;
                case 'CREDIT_CARD':
                    setCCFields(data);
                    break;
                default:
                    setCCFields(data);
            }
        }
    });
}

function shippingAddressForBilling () {
    var $sameAsShipping = $('#same-as-shipping'),
        $sameAsShippingInput = $('input[name$="_sameAsShipping"]'),
        $enterNewAddress = $('#enter-new-address');

    if ($sameAsShippingInput.val() === 'false') {
        newAddressEvent();
    } else {
        sameAddressEvent();
    }

    $sameAsShipping.on('click', sameAddressEvent);
    $enterNewAddress.on('click', newAddressEvent);
}

function sameAddressEvent () {
    var $sameAsShippingInput = $('input[name$="_sameAsShipping"]'),
        $enterNewAddress = $('#enter-new-address'),
        $checkoutForm = $('.checkout-billing'),
        $billingAddressSet = $('.billing-address-set');

    var selectedAddress = $(this).data('address');
    if (!selectedAddress) { return; }
    util.fillAddressFields(selectedAddress, $checkoutForm);
    $billingAddressSet.addClass('hide');
    $sameAsShippingInput.val(true);
    $enterNewAddress.attr('checked', false);
    $enterNewAddress.attr('disabled', false);
    $(this).attr('disabled', true);
    formPrepare.validateForm();
}

function newAddressEvent () {
    var $sameAsShipping = $('#same-as-shipping'),
        $sameAsShippingInput = $('input[name$="_sameAsShipping"]'),
        $checkoutForm = $('.checkout-billing'),
        $billingAddressSet = $('.billing-address-set');

    util.clearAddressFields($checkoutForm);
    $billingAddressSet.removeClass('hide');
    $sameAsShippingInput.val(false);
    $sameAsShipping.attr('checked', false);
    $sameAsShipping.attr('disabled', false);
    $(this).attr('disabled', true);

    //prevent error on init but works after a real error occurs
    if ($('.states .select-style').hasClass('select-style-error')) {
        $('.states .select-style').removeClass('select-style-error');
    }
    formPrepare.validateForm();
}


/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');
    var summary = $('#secondary');

    function hidecustomPayments () {
        summary.find('.paypal').addClass('visually-hidden');
        summary.find('.applepay').addClass('visually-hidden');
    }
    hidecustomPayments();

    var dataMethod = paymentMethodID;
    if (paymentMethodID == 'SA_SILENTPOST') {
        dataMethod = 'CREDIT_CARD';
    }
    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="'
            + dataMethod + '"]');

    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    if (paymentMethodID == 'VISA_CHECKOUT') {
        $('.continue-place-order').hide();
        $('.visacheckoutbutton').show();
    } else if (paymentMethodID == 'PAYPAL'
            || paymentMethodID == 'PAYPAL_CREDIT') {
        $('#billingAgreementCheckbox').attr('checked', false);
        $('.continue-place-order').hide();
        $('#fakeContinueBtn').hide();
        summary.find('.paypal').removeClass('visually-hidden');
    } else {
        $('.continue-place-order').show();
        $('.visacheckoutbutton').hide();
    }
    if (paymentMethodID == 'CREDIT_CARD' || paymentMethodID == 'SA_SILENTPOST') {
        $('.spsavecard').show();
        hidecustomPayments();
        $('#fakeContinueBtn').show();
    } else if ((paymentMethodID == 'SA_REDIRECT' || paymentMethodID == 'SA_IFRAME')
            && SitePreferences.TOKENIZATION_ENABLED) {
        $('.spsavecard').show();
    } else {
        $('.spsavecard').hide();
    }

    if (paymentMethodID == 'DW_APPLE_PAY') {
        hidecustomPayments();
        $('#fakeContinueBtn').hide();
        summary.find('.applepay').removeClass('visually-hidden');
    }


    var isBicRequired = $selectedPaymentMethod.data('bicrequired');
    if (isBicRequired) {
        $('.bic-section').show();
    } else {
        $('.bic-section').hide();
    }

    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value="' + paymentMethodID + '"]').prop('checked', 'checked');

    formPrepare.validateForm();
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary(step) {
    var $summary = $('#secondary.summary'),
        $formRowButton = $summary.find('.summary-bottom .form-row-button'),
        fakeCBtn = $summary.find('#fakeContinueBtn'),
        applePayBtnCntr = $summary.find('.applepay'),
        selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]:checked').val();

    // load the updated summary area
    var summaryUrl = util.appendParamToURL(Urls.summaryRefreshURL, 'checkoutstep', step);
    $summary.load(summaryUrl, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();

        // re-add continue button and Apple Pay button
        $formRowButton.empty().prepend(applePayBtnCntr);
        $formRowButton.prepend(fakeCBtn);
        
        //trigger show/hide "continue" buttons for paypal/applepay/cc
        updatePaymentMethod((selectedPaymentMethodID) ? selectedPaymentMethodID : '');
        
        if (SitePreferences.ISPAYPALENABLED && $('.paypal-button-container-cart1').length > 0){
            // re-render PayPal button
            var config = window.paypal.config;
            config.style ={
                label: 'checkout',
                size:  'responsive', // small | medium
                shape: 'rect',   // pill | rect
                color: 'gold',
                tagline: false
            };
            window.paypal.Button.render(config, '.paypal-button-container-cart1');
        }

        fluidconfigure.initMiniLineItems();
    });
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {

    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $giftCertPin = $('input[name$="_giftCertPin"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

    formPrepare.init({
        isBilling: true,
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]',
        fakeContinue:'#fakeContinueBtn'
    });

    $('#secondary').on('click','#fakeContinueBtn', function(e) {
        e.preventDefault();
        $('button[name$="_billing_save"]').trigger('click');
    });

    var $ccContainer = $($checkoutForm).find('.payment-method').filter(
            function() {
                return $(this).data('method') == 'CREDIT_CARD';
            });
    $($checkoutForm).find('input[name$="_selectedCardID"]').val('');

    $ccContainer.find('input[name*="_number"]').on('change', function() {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    $ccContainer.find('input[name$="_owner"]').on('change', function() {
        $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
    });
    /*
    $ccContainer.find('select[name$="creditCard_type"]')
            .on(
                    'change',
                    function() {
                        $($checkoutForm).find('input[name$="_selectedCardID"]')
                                .val('');
                    });
    */
    $ccContainer
            .find('select[name*="expiration"]')
            .on(
                    'change',
                    function() {
                        $($checkoutForm).find('input[name$="_selectedCardID"]')
                                .val('');

                        var selectedPaymentMethodID = $(
                                'input[name$="_selectedPaymentMethodID"]:checked')
                                .val();
                        var cardNumber = $($checkoutForm).find(
                                'input[name*="_number"]').val();
                        if (cardNumber.indexOf('****') != -1
                                && selectedPaymentMethodID == 'SA_SILENTPOST') {
                            $($checkoutForm).find('input[name*="_number"]')
                                    .val('');
                        }

                    });

    // checking if some payment method already user
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : '');

    $selectPaymentMethod.on('click', 'input[type="radio"]', function() {
        updatePaymentMethod($(this).val());
    });


    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {
            $($checkoutForm).find('input[name$="_selectedCardID"]').val('');
            return;
        }
        populateCreditCardForm(cardUUID, selectedPaymentMethod);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $('*[name*="creditCard_expiration"]').on('change', function () {
        $('.error-message').remove();
    });

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        progress.show($('.payment-method.gcpayment'));
        var $balance = $('.balance'),
            couponContainer = $('.gcpayment .billing-coupon'),
            error = couponContainer.find('.giftcert-error');

        error.empty();

        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            progress.hide();
            return;
        }

        if ($giftCertPin.length === 0 || $giftCertPin.val().length === 0) {
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_PIN_MISSING);
            progress.hide();
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), $giftCertPin.val(), function (data) {
            if (!data || !data.giftCertificate || !data.message) {
                // $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                error.html(Resources.GIFT_CERT_INVALID);
                progress.hide();
                return;
            }
            $balance.html(data.message).removeClass('error').addClass('success');
            progress.hide();
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        progress.show($('.payment-method.gcpayment'));
        var code = $giftCertCode.val(),
            pin = $giftCertPin.val(),
            $error = $checkoutForm.find('.giftcert-error');

        $error.empty();
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            progress.hide();
            return;
        }

        if (pin.length === 0) {
            $error.html(Resources.GIFT_CERT_PIN_MISSING);
            progress.hide();
            return;
        }

        $error.empty();
        var url = util.appendParamsToUrl(Urls.addGiftCert, {giftCertCode: code, giftCertPin: pin, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data || $.isEmptyObject(data)) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success && data.message) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                progress.hide();
                return;
            } else {
                window.location.assign(Urls.billing);
                progress.hide();
            }
        });
    });

    $('#primary').on('click', '#add-coupon', function (e) {
        e.preventDefault();
        var $error = $('#primary').find('.coupon-error'),
            code = $couponCode.val(),
            $success = $('.redemption.coupon'),
            currentCheckoutStep = $(this).attr('data-checkoutstep');

        $success.find('.success').remove();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                if (data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    msg = $('<div class="success">' + msg + '</div>');
                } else {
                    msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                    fail = true;
                }
            }
            if (fail) {
                $error.html('<p>' + msg + '</p>').show();
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success) {
                if (data.baskettotal === 0) {
                    window.location.assign(Urls.billing);
                } else {
                    updateSummary(currentCheckoutStep);
                    $error.hide();
                    $success.prepend('<div class="success">' + data.message + '</div>');
                }
            }  else if (data.success == false && data.status == 'COUPON_CODE_ALREADY_IN_BASKET') {
                $error.hide();
                $success.prepend('<div class="success">' + data.message + '</div>');
            }
        });
    });

    shippingAddressForBilling();
    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    if (window.ApplePaySession) {
        $('.payment-method-options').find('.apple-pay').removeClass('visually-hidden');
    }
};
