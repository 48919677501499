'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    fluidconfigure = require('../../fluidconfigure'),
    shipping = require('./shipping');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    $('#secondary').on('click','#fakeContinueBtnSummary', function(e) {
        e.preventDefault();
        $('.submit-order').find('button[type="submit"]').trigger('click');
    });

    fluidconfigure.initMiniLineItems();
    fluidconfigure.initOrderReviewLineItems();
    initMiniSummary();
};

function initMiniSummary() {
    $(document).on('click', '.checkout-products-wrapper h5', function() {
        var $checkoutProductsWrapper = $('.checkout-products-wrapper');
        var $checkoutProducts = $checkoutProductsWrapper.find('.checkout-mini-cart');
        var $checkoutProductsToggle = $checkoutProductsWrapper.find('h5');
        $checkoutProducts.slideToggle('fast', function() {
            $('#secondary.summary').toggleClass('sticky');
            $(this).parent().removeClass('rail-closed');
            if ($checkoutProducts.is(':visible')) {
                $checkoutProductsToggle.addClass('opened');
                updateSummaryDisplayPreference(1);
                if ($(window).scrollTop() > 200 && $(window).width() > 767) {
                    $('html,body').animate({
                        scrollTop: $('.checkout-products-wrapper').offset().top - 80
                    }, 'fast');
                }
            } else {
                $checkoutProductsToggle.removeClass('opened');
                updateSummaryDisplayPreference(0);
            }
        });
    });
}

function updateSummaryDisplayPreference(value) {
    $.ajax({
        type: 'POST',
        url: Urls.updateSummaryDisplayPreference,
        data: {
            visibility: value
        }
    });
}
