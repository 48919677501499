/**
* Description of the module and the logic it provides
*
* @module cartridge/js/avs/suggested
*/

'use strict';
var dialog = require('../dialog');

var suggested = {
    init: function () {
        this.suggestedHandler();
    },
    
    suggestedHandler: function () {
        var rawContent = $('.shipping-suggested-addresses').html();
        $('#primary').find('.shipping-suggested-addresses').remove();
        dialog.create({
            options: {
                width: 485
            }
        });
        dialog.openWithContent({
            content: rawContent
        })
        $('.suggested-addresses-list input[type="radio"]').on('click', function () {
            var input = $(this);
            var address = input.data('address');
            $('input[name$="_address1"]').val(address.address1).trigger('change');
            $('input[name$="_city"]').val(address.city).trigger('change');
            $('input[name$="_postal"]').val(address.postalCode).trigger('change');
            $('input[name$="_country"]').val(address.countryCode).trigger('change');
            if (input.attr('id') === 'use-my') {
                $('#skipAVSCheck').val('1');
            } else {
                $('#skipAVSCheck').val('0');
            }
        });
        $('#use-my').click();
        $('#close-suggested-list').on('click', function () {
            $('#fakeContinueBtn').click();
            dialog.close();
        })
    }
}
module.exports = suggested;
