/**
* Description of the module and the logic it provides
*
* @module cartridge/js/registration
*/
'use strict';

var util = require('../util'),
    dialog = require('../dialog');

var registration = {
    init: function () {
        $('#create-account').on('click', registration.popup);
        $(document).on('change', 'select[name$="customer_bMonth"]', registration.updateDays);
    },

    updateDays: function () {
        var bDay =  $('select[name$="_customer_bDay"]');
        bDay.find('option').remove();
        var month = $(this).val(),
            daysCount = new Date((new Date()).getFullYear(), month, 0).getDate();

        var leadingZero = function (i) {
            return i.length == 1?'0'+i:i;
        }
        for (var i=1; i<=daysCount; i++) {
            var val = leadingZero(i);
            bDay.append($('<option></option>').attr('value', val).text(val));
        }
    },
    
    popup: function (e) {
        e.preventDefault();
        var url = window.Urls.registration;
        if (util.isMobile()) {
            if (window.pageContext.ns == 'cart') {
                window.location.href = url + '?targetLocation=COShipping-Start';
            } else {
                if ($('#bvLocation').size() > 0) {
                    window.location.href = $('#bvLocation').val(); 
                } else {
                    window.location.href = url;
                }
            }
            
        } else {
            $(document).off('submit', '#RegistrationForm', registration.formHandler);
            $(document).on('submit', '#RegistrationForm', registration.formHandler);
            url = url + '?format=ajax';
            dialog.open({
                url: url,
                options: {
                    width: 'auto',
                    height: 'auto'
                }
            });
        }
    },

    formHandler: function (e) {
        e.preventDefault()
        var form = $(this);
        if (form.valid()) {
            $.post(form.attr('action') + '?format=ajax', form.serialize(), function (data) {
                if (data && data.indexOf('registration-success') != -1) {
                    if (window.pageContext.ns == 'cart') {
                        window.location.href = window.Urls.shippingPage;
                    } else {
                        if ($('#bvLocation').size() > 0) {
                            window.location.href = $('#bvLocation').val(); 
                        } else {
                            window.location.href = window.Urls.accountShow + '?registration=true';
                        }
                    }
                } else {
                    $('.registration-form-container').first().replaceWith(data);
                }
            })
        }
    }
}

module.exports = registration;
