/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    backtotop = require('./backtotop'),
    blog = require('./pages/blog/index'),
    minicart = require('./minicart'),
    modules = require('./modules'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    homepopup = require('./homepopup'),
    omniture = require('./omniture/init'),
    catlanding = require('./cat-landing'),
    sizechart = require('./sizechart'),
    storeinventory = require('./storeinventory'),
    subscribe = require('./bluemartini/subscribe'),
    iparcel = require('./iparcel'),
    wlStatus = require('./wishlist_status'),
    multisell = require('./multisell');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('picturefill');
require('./carousel')();

/**
 * Header menu init
*/
function headerMenuInit() {
    if (!util.isMobileSize()) {
        $('.menu-category-container').accessibleMegaMenu({
            uuidPrefix: 'accessible-megamenu',
            menuClass: 'nav-menu',
            topNavItemClass: 'nav-item',
            panelClass: 'level-2',
            panelGroupClass: 'sub-nav-group',
            hoverClass: 'hover',
            focusClass: 'focus',
            openClass: 'open',
            openDelay: 250,
            closeDelay: 250
        });
        $('.nav-menu .has-third-menu > a').removeClass('has-sub-menu');
    } else {
        $('.nav-menu .has-third-menu > a').addClass('has-sub-menu');
    }
}
/**
 * My Account nav menu init
*/
function mobileAccountNav() {
    util.smartResize(function() {
        util.move(util.isDesktopSize(), '.page-title', '.account-mobile-nav-container', '.desktop-page-title-container');
        if (!util.isDesktopSize()) {
            $('.account-mobile-nav-container').empty();
        }
    })
}


/**
 * add href so shipto and flag links will be ready with keyboard navigate
*/
function addLinkShipTo() {
    $('.country-selector .iparWelcome').attr('href', 'javascript:void(0)');
    $('.country-selector .iparWelcome').attr('title', Resources.Ship_TO_TEXT);
}


/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    // Make sure that the SVGs work properly in older browsers
    svg4everybody();

    // Back to top button for Homepage, PDP, Grid
    backtotop();

    //Size Chart pages
    if ($('.size-chart-container').length > 0) {
        sizechart();
    }

    addLinkShipTo();

}
function homepageAddToWishlist() {
    if ($('.pt_storefront').length > 0) {
        wlStatus.init();
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    orderconfirmation: require('./pages/orderconfirmation'),
    storelocator: require('./pages/storelocator')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        headerMenuInit();

        // init specific global components
        modules.init();
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        mobileAccountNav();
        searchplaceholder.init();
        catlanding.init();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        homepopup.init();
        omniture.init();
        $(window).load(function(){
            $('.product-usd').removeClass('hide-USD');
        });
        blog.init();
        storeinventory.init();
        subscribe.init();
        homepageAddToWishlist();
        iparcel.init();
        multisell.init();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
